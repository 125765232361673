.block-downloads {

    .column-11,
    .column-9 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-2 {
        margin-top: 9rem;

        .column-9 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10rem;
        }
    }

    .download-item {
        border-bottom: 1px solid rgba(0, 21, 54, 0.2);

        &:nth-child(1),
        &:nth-child(2) {
            border-top: 1px solid rgba(0, 21, 54, 0.2);
        }

        a {
            padding: 1.8rem 1rem;
            display: flex;
            align-items: center;
            color: $blue-1;
            transition: color 0.6s $easeOutQuart;

            html.desktop & {
                &:hover {
                    color: $red-1;

                    svg {
                        transform: translate(0, 4px);
                    }
                }
            }

            .title {
                margin-right: 2rem;
                @include SuisseIntlSemiBold(1.4rem, 1.6rem);
            }

            svg {
                flex: 0 0 auto;
                margin-left: auto;
                width: 1.3rem;
                height: auto;
                transition: transform 0.6s $easeInOutQuart;
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            margin-top: 4rem;

            .column-9 {
                grid-template-columns: repeat(1, 1fr);
                grid-column-gap: 2rem;
            }
        }

        .download-item {
            &:nth-child(2) {
                border-top: none;
            }
        }
    }

}