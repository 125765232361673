html {
    font-size: calc(1em * .625);
    //font-size: 16px; // baseFontSize
    //font-size: clamp(100%, 1rem + 2vw, 16px);

    &.has-scroll-smooth {
        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    /*&:not(.is-mobile) {
        overflow: hidden !important;
    }*/

    @media (min-width: 1024px) {
        font-size: .67568vw;

        @media (min-width: 1440px) {
            font-size: 10px;
        }
    }
}

body {
    position: relative;
    font-size: 1.8rem;
    line-height: 1.6;
    min-height: 100%;
    @include SuisseIntlBook(1.8rem, 2.6rem);
    color: $blue-3;
}

.wrapper {
    opacity: 0;
    position: relative;
    overflow: hidden;
    transition: opacity 1s $easeOutQuart;

    &.visible {
        opacity: 1;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.otgs-development-site-front-end {
    display: none !important;
}

/**
PATTERN
 */
.bg-pattern__grey {
    background: transparent url('../images/patterns/grey.png') repeat left top;
    background-size: 390px 390px;
}

.bg-pattern__blue {
    background: transparent url('../images/patterns/blue.png') repeat left top;
    background-size: 390px 390px;
}

.bg-pattern__red {
    background: transparent url('../images/patterns/red.png') repeat left top;
    background-size: 390px 390px;
}

/**
ARROWS
 */
.arrows {
    display: flex;
    align-items: center;

    .prev,
    .next {
        flex: 0 0 auto;
        cursor: pointer;
        width: 4rem;
        height: 4rem;
        border: 1px solid white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        background-color: transparent;
        transition: background-color 0.6s $easeOutQuart, border-color 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;

        &.large {
            width: 8.6rem;
            height: 8.6rem;

            svg {
                width: 1.6rem;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        svg {
            stroke: white;
            width: 1rem;
            height: auto;
            transition: stroke 0.6s $easeOutQuart;
        }

        html.desktop & {

            &:hover {
                border-color: $red-1;
                background-color: $red-1;

                svg {
                    stroke: white;
                }
            }
        }

        &.swiper-button-disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .prev {
        padding-right: 0.4rem;

        svg {
            transform: rotate(-180deg);
        }
    }

    .next {
        padding-left: 0.4rem;
    }

    .button-rect {
        flex: 0 0 auto;
        margin-top: 0 !important;
        width: auto !important;
    }

}

/**
PUSH CONTACT
 */
.push-contact {
    color: $blue-1;
    max-width: 24rem;

    .contact__topbar {
        @include SuisseIntlSemiBold(1.8rem, 2.6rem);
        color: $blue-1;
    }

    .inline-link {
        border-color: rgba(0, 24, 51, 0.3);

        a {

            .icon {

                svg {
                    fill: $red-1;
                }
            }

            .text {
                color: $blue-1;
            }

            .arrow-right {
                fill: $blue-1;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        max-width: 100%;
    }
}

/**
FIXED CONTACT
 */
.fixed-contact {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 90;
    transform: translate(0, 100%);
    transition: transform 1.2s $easeInOutQuart;

    &.visible {
        transform: translate(0, 0);
    }

    body.version-standalone.single-realisation & {
        display: none;
    }

    &.is-open {
        .fixed-contact__content {
            transform: translate(0, -100%);
        }

        .fixed-contact__topbar {
            svg {
                transform: rotate(0);
            }
        }
    }

    .fixed-contact__topbar {
        position: relative;
        cursor: pointer;
        height: 9rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem;
        @include SuisseIntlSemiBold(1.6rem, 1.6rem);
        background-color: $red-1;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: $red-3;
            left: 0;
            top: 0;
            z-index: 0;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
        }

        span {
            flex: 0 0 auto;
        }

        svg {
            margin-left: 2rem;
            flex: 0 0 auto;
            width: 1.4rem;
            height: auto;
            transform: rotate(-180deg);
            transition: transform 0.6s $easeInOutQuart;
        }

        html.desktop & {
            &:hover {
                &:before {
                    transform: scaleX(1);
                }
            }
        }
    }

    .fixed-contact__content {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(100%, -100%);
        background-color: $red-1;
        color: white;
        display: flex;
        align-items: stretch;
        padding: 4rem;
        width: 62rem;
        border: 1px solid $red-3;
        z-index: 2;
        transition: transform 0.8s $easeInOutQuart;

        .text-content {
            p,
            ul {
                @include SuisseIntlSemiBold(1.4rem, 2.2rem);
                color: white;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }
            }

            ul {
                padding-left: 4rem;

                li {
                    margin-top: 0;
                    &:before {
                        background-color: white;
                    }
                }
            }
        }

        .left,
        .right {
            width: 50%;
        }

        .right {
            margin-left: 5rem;
        }

        svg {
            fill: white;
            transition: transform 0.6s $easeInOutQuart;
        }

        .inline-link {
            html.desktop & {
                &:hover {

                    .text {
                        color: white;
                    }

                    .arrow-right {
                        fill: white;
                        transform: translate(0.4rem, 0);
                    }
                }
            }
        }
    }
}

svg,
img {
    display: block;
}

/**
REALISATION ITEM
 */
.realisation-item {
    box-sizing: border-box;

    html.desktop & {

        &:hover {

            .thumbnail {

                img {
                    transform: scale(1);
                }

                .over {
                    .button-arrow-circle {
                        border-color: $red-1;
                        background-color: $red-1;

                        svg {
                            transform: translate(4px, 0);
                            fill: white;
                        }
                    }
                }
            }
        }
    }

    .thumbnail {
        width: 100%;
        height: 43rem;
        background-color: $blue-1;
        position: relative;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: scale(1.1);
            transition: transform 1.2s $easeInOutQuart;
        }

        &:after {
            content: "";
            background: rgb(0,24,51);
            background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51,1) 100%);
            @include full-absolute;
            z-index: 2;
            pointer-events: none;
        }

        .over {
            padding: 3rem;
            @include full-absolute;
            z-index: 3;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .title {
                @include SuisseIntlSemiBold(2.2rem, 2.8rem);
                max-width: 25rem;
                color: white;
            }

            .tags {
                margin-top: 2rem;
                padding-right: 6rem;

                ul {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-left: -1rem;

                    li {
                        @include SuisseIntlSemiBold(1.2rem, 1.4rem);
                        background-color: $red-1;
                        color: white;
                        padding: 0.6rem;
                        border-radius: 0.2rem;
                        margin-left: 1rem;
                        margin-top: 1rem;
                    }
                }
            }

            .button-arrow-circle {
                position: absolute;
                right: 3rem;
                bottom: 3rem;
                z-index: 3;
            }
        }
    }

    .metas {
        background-color: white;
        height: 6rem;
        display: flex;
        align-items: center;
        padding: 0 3rem;

        .tag {
            @include SuisseIntlSemiBold(1.4rem, 1.4rem);
            color: $blue-1;
        }

        .city {
            margin-left: 2rem;
            display: flex;
            align-items: center;

            svg {
                width: 1.3rem;
                height: auto;
                fill: $blue-1;
            }

            .text {
                margin-left: 1rem;
                @include SuisseIntlSemiBold(1.2rem, 1.2rem);
                color: $blue-1;
            }
        }
    }

    &.card-item {
        box-shadow: 0 12px 24px 0 rgba(0, 24, 51, 0.16);

        .thumbnail {
            height: 25rem;
        }

        .metas {
            background-color: white;
            height: 25rem;
            flex-direction: column;
            padding: 3rem;
            align-items: flex-start;

            .title {
                @include SuisseIntlSemiBold(2.4rem, 3.2rem);
                color: $blue-1;
            }

            .description {
                margin-top: 1rem;
                @include SuisseIntlSemiBold(2rem, 2.8rem);
                color: $blue-3;
            }
        }
    }
}

/**
INFOBULLE
 */

.gm-style .gm-style-iw-c {
    border-radius: 0;
    background-color: $red-1;
    padding: 2rem;
    max-width: 330px !important;
    width: 100%;
}

.gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style .gm-style-iw-tc::after {
    background-color: $red-1;
}

#infobulle {
    background-color: $red-1;

    .content__inner {
        padding-right: 2rem;
        padding-bottom: 2rem;
        color: white;
        @include SuisseIntlBook(1.4rem, 2.2rem);

        b,
        strong {
            @include SuisseIntlSemiBold(1.8rem, 2.2rem);
            display: block;
            margin-bottom: 1rem;
        }

        h2,
        h3,
        p {
            margin-top: 1rem;

            &:first-child {
                margin-top: 0;
            }
        }

        h6 {
            padding-left: 0 !important;
        }
    }

    .inline-link {
        a {
            .icon {
                height: auto;
            }
        }

        html.desktop & {
            &:hover {
                a {
                    .text {
                        color: white;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile){
        h6 {
            font-size: 1.4rem;
            line-height: 1.8;
        }
    }
}

#error {
    height: 100vh;
    background-color: $blue-1;

    .grid,
    .row {
        height: 100%;
    }

    .section-1 {
        height: 100%;

        .column-12 {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        h1 {
            font-size: 3rem;
            line-height: 3.8rem;
            text-align: center;
            color: white;
        }

        .button-rect {
            margin-top: 3rem;
        }
    }
}

@media screen and (max-width: $mobile) {

    body {
        font-size: 1.6rem;
    }

    .fixed-contact {
        display: none !important;
    }

    .realisation-item {
        .thumbnail {
            height: 34rem;
        }
    }

}