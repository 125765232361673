.block-timeline {
    background-color: $red-3;

    .grid {
        z-index: 2;
    }

    .g-2 {
        max-width: 100%;
        margin-top: 5rem;
        padding-top: 5rem;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    .r-1 {
        .up-title {
            color: white;
        }
    }

    .r-2 {
        margin-top: 6rem;

        .c-1 {

            .text-content {
                color: white;

                h3,
                h2 {
                    color: inherit;
                }
            }
        }

        .c-2 {
            margin-left: auto;

            .text-content {
                color: white;
            }
        }
    }

    .r-3 {

        .swiper {
            @extend .grid;
            max-width: 140rem;
            height: 48rem;
            overflow: visible;
            cursor: grab;

            .swiper-slide {
                box-sizing: border-box;
                height: 48rem;
                position: relative;

                .thumbnail {
                    width: 100%;
                    height: 100%;
                    transform-origin: 50% 50%;
                    transform: scale(0.75);
                    transition: transform 0.8s $easeInOutQuart;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    &:before {
                        content: "";
                        @include full-absolute;
                        background: rgb(0,24,51);
                        background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51,0.6) 100%);
                        z-index: 2;
                    }
                }

                .over {
                    opacity: 0;
                    transform: translate3d(0, 10px, 0) rotate(0.01deg);
                    @include full-absolute;
                    z-index: 2;
                    color: white;
                    @include SuisseIntlSemiBold(1.8rem, 2.8rem);
                    display: flex;
                    align-items: flex-end;
                    padding: 3rem;
                    transition: opacity 0.8s $easeOutQuad 0.2s, transform 0.8s $easeOutQuad 0.2s;
                }

                &.swiper-slide-active {

                    .thumbnail {
                        transform: scale(1);
                    }

                    .over {
                        opacity: 1;
                        transform: translate3d(0, 0, 0) rotate(0.01deg);
                    }
                }
            }
        }
    }

    .r-4 {
        margin-top: 5rem;
        justify-content: center;
        align-items: center;

        .start-index,
        .end-index {
            @include SuisseIntlLight(4.4rem, 5rem);
            color: white;
        }

        .start-index {
            margin-left: 3rem;
        }

        .end-index {
            margin-right: 3rem;
        }

        .progress {
            margin: 0 2rem;
            width: 12rem;
            background-color: rgba(255, 255, 255, 0.3);
            height: 2px;

            .progress__line {
                background-color: white;
                height: 100%;
                width: 100%;
                transform-origin: 0 0;
                transform: scaleX(0);
                transition: transform 0.6s $easeInOutQuart;
            }
        }
    }

    .current-year {
        position: absolute;
        left: 50%;
        margin-left: 10rem;
        top: 30rem;
        z-index: 10;
        color: white;
        @include SuisseIntlLight(13rem, 13rem);
    }

    .pattern {
        pointer-events: none;
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .bg-pattern__red;
        width: 80rem;
        height: 80rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $red-3;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            margin-top: 4rem;

            .c-2 {
                margin-top: 3rem;
            }
        }

        .g-2 {
            padding-top: 13rem;
            padding-bottom: 0;

            .current-year {
                top: 6rem;
                left: 0;
                width: 100%;
                text-align: center;
                margin-left: 0;
            }
        }

        .r-3 {

            .swiper {
                max-width: 100%;
                width: 100%;
                height: 34rem;

                .swiper-slide {
                    height: 34rem;
                }
            }
        }

        .r-4 {

            .start-index,
            .end-index,
            .arrows {
                display: none;
            }

            .progress {
                width: 100%;
            }
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 30rem;
            z-index: 0;
            pointer-events: none;
        }
    }

}