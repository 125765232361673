.block {
    position: relative;
    padding: 12rem 0;
    border-top: 1px solid rgba(170, 170, 170, 0.3);
    overflow: hidden;

    &:first-child {
        margin-top: 0;
    }

    &.no-pt {
        padding-top: 0;
    }

    &.no-pb {
        padding-bottom: 0;
    }

    &.no-bt {
        border-top: none;
    }

    &.add-mt {
        margin-top: 10rem;
    }

    &.add-mb {
        margin-bottom: 10rem;
    }
}

@media screen and (max-width: $mobile) {
    .block {
        padding: 6rem 0;
    }

    .block-head + .block {
        border-top: none !important;
    }
}

@import "block-head";
@import "block-skills";
@import "block-approach";
@import "block-key-numbers";
@import "block-title-quote-text";
@import "block-map";
@import "block-certifications";
@import "block-realisations";
@import "block-slider";
@import "block-jobs";
@import "block-accordion";
@import "block-boxes";
@import "block-images";
@import "block-images-slider";
@import "block-text";
@import "block-portraits";
@import "block-timeline";
@import "block-news";
@import "block-image-hero";
@import "block-contact";
@import "block-downloads";