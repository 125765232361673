.block-images {
    @extend .bg-pattern__grey;

    &:before {
        content: "";
        position: absolute;
        background-color: white;
        width: 200%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translate(-55%, 0) skewX(-40deg);
    }

    .grid {
        z-index: 2;
    }

    .r-1 {
        margin-bottom: 6rem;

        .c-2 {
            margin-left: auto;
            color: $blue-3;
        }
    }

    .r-2 {
        .column-12 {
            display: grid;
            grid-template-columns: repeat(1, 1fr);

            &.grid-2 {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 2rem;

                &.no-gap {
                    grid-column-gap: 0;
                }
            }
        }
    }

    .image-item {
        height: auto;
        width: 100%;

        .image-item__src {
            position: relative;
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .image-item__content {
            @include full-absolute;
            background: rgb(0,24,51);
            background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51,1) 100%);
            z-index: 2;

            .image-item__content-inner {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                padding: 6rem 30% 6rem 6rem;

                html.desktop & {
                    &:hover {
                        .button-arrow-circle {
                            border-color: $red-1;
                            background-color: $red-1;

                            svg {
                                transform: translate(4px, 0);
                                fill: white;
                            }
                        }
                    }
                }
            }

            .text-container {
                position: relative;
                overflow: hidden;
                margin-top: 1rem;
                height: 0;
            }

            .title {
                color: white;
                @include SuisseIntlSemiBold(3.8rem, 4.2rem);
            }

            .description {
                @include SuisseIntlBook(1.8rem, 2.6rem);
                color: white;
            }

            .button-arrow-circle {
                margin-top: 3rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            .column-12 {

                &.grid-2 {
                    grid-template-columns: repeat(1, 1fr);
                    grid-row-gap: 2rem;
                }
            }
        }

        .image-item {
            //height: 35rem;

            .image-item__content {

                .image-item__content-inner {
                    padding: 4rem 2rem;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: row;
                }

                .title {
                    font-size: 3rem;
                    line-height: 3.8rem;
                }

                .button-arrow-circle {
                    flex: 0 0 auto;
                    margin-left: 1rem;
                }

                .text-container {
                    display: none;
                }
            }
        }
    }

}