.block-map {
    border-top: 1px solid rgba(170, 170, 170, 0.3);

    .column-4 {
        padding: 10rem 0;

        .text-content {
            margin-top: 6rem;

            h3 {
                color: $blue-1;
            }

            p {
                color: $blue-3;
            }
        }

        .push-contact {
            margin-top: 3rem;
        }
    }

    #map {
        position: absolute;
        right: 0;
        top: 0;
        width: 50vw;
        height: 100%;
        background-color: $blue-1;
    }

    @media screen and (max-width: $mobile) {
        .column-4 {
            padding: 6rem 0;

            .text-content {
                margin-top: 4rem;
            }
        }

        #map {
            position: relative;
            top: auto;
            right: auto;
            height: 47rem;
            width: 100%;
        }
    }
}