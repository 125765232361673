.block-title-quote-text {

    &.add-left-padding-1 {
        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .c-1 {
            @include set-grid-prop(margin-left, 1, 1);
            @include set-grid-prop(width, 4, 5);
            padding-right: 4rem;
        }
    }

    .r-2 {
        margin-top: 6rem;

        &:first-child {
            margin-top: 0;
        }
    }

    .c-1 {
        color: $blue-1;
    }

    .c-2 {
        margin-left: auto;
        @include set-grid-prop(margin-right, 1, 1);
    }

    .buttons {
        margin-top: 3rem;
        display: flex;
        align-items: center;

        .button-rect {
            display: block;
            margin-left: 2rem;

            &:first-child {
                margin-left: 0;
            }
        }

        &:first-child {
            margin-top: 0;
        }
    }

    .text-content {
        margin-top: 3rem;

        &:first-child {
            margin-top: 0;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            margin-top: 4rem;
        }

        .c-2 {
            margin-top: 4rem;
        }

        .buttons {
            flex-direction: column;

            .button-rect {
                flex: 1 0 auto;
                width: 100%;
                margin: 2rem 0 0 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}