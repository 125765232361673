#single {

    .page-wrapper > .pattern {
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        @extend .bg-pattern__grey;
        width: 70rem;
        height: 70rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: white;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    .single-head {
        padding-top: 20rem;
        padding-bottom: 6rem;

        .date {
            @extend .up-title;
        }

        h1 {
            margin-top: 3rem;
            @include SuisseIntlLight();
        }

        .categories {
            margin-top: 3rem;
            @include SuisseIntlSemiBold(1.8rem, 2.2rem);
            color: $red-1;

            ul {
                display: flex;
                align-content: center;

                li {
                    margin-left: 1rem;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .single-content {
        padding-bottom: 10rem;

        .column-8 {
            @include set-grid-prop(margin-left, 2, 2);
        }

        .cover {
            width: 100%;
            height: 63rem;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .share {
            padding: 2rem 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $grey;

            .legend {
                @extend .up-title;
            }

            ul {
                display: flex;
                align-items: center;
                margin-left: 3rem;

                li {
                    width: 4rem;
                    height: 4rem;

                    a {
                        width: 4rem;
                        height: 4rem;
                        border: 1px solid rgba(170, 170, 170, 0.3);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        transition: border-color 0.6s $easeOutQuart;

                        html.desktop & {
                            &:hover {
                                border-color: $blue-1;
                            }
                        }
                    }

                    svg {
                        width: 1.4rem;
                        height: auto;
                        fill: $blue-1;
                    }

                    &.facebook {
                        margin-left: 1rem;

                        svg {
                            width: 0.7rem;
                        }
                    }
                }
            }
        }

        .text-content {
            margin-top: 10rem;
        }
    }

    @media screen and (max-width: $mobile) {

        .page-wrapper > .pattern {
            top: 8rem;
            width: 40rem;
            height: 40rem;
        }

        .single-head {

            .categories {

                ul {
                    flex-wrap: wrap;
                    font-size: 1.4rem;
                    line-height: 1.2;
                }
            }
        }

        .single-content {

            .cover {
                height: 24rem;
            }

            .text-content {
                margin-top: 6rem;
            }
        }
    }

}