#form {

    .form__head {
        background-color: $blue-1;
        height: 50rem;
        border-bottom: 1px solid #EDEDED;
        padding-bottom: 10rem;

        .grid {
            padding-top: 20rem;
            z-index: 2;
        }


        h1 {
            color: white;
            @include SuisseIntlLight();
        }

        .pattern {
            z-index: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            bottom: 0;
            @extend .bg-pattern__blue;
            width: 50rem;
            height: 50rem;
            z-index: 0;
            pointer-events: none;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: $blue-1;
                width: 200%;
                height: 100%;
                transform: translate(-100%, -50%) skewX(-45deg);
                z-index: 2;
            }
        }
    }

    .form__content {
        margin-top: -10rem;
        z-index: 2;
        padding-bottom: 7.3rem;
    }

    .gform_wrapper {
        background-color: $grey;
        padding: 6rem 10rem;
        color: $blue-1;

        .gfield_validation_message {
            display: none !important;
        }

        .gform_validation_errors {
            border-radius: 0;
            padding: 1rem 3rem;
            margin-bottom: 2rem;

            span {
                display: none;
            }
        }

        &.gravity-theme {

            .gform_fields {
                grid-row-gap: 3rem;
                //grid-auto-flow: row;
            }
        }

        form {
            margin-top: 2rem;
            max-width: 70rem;
        }

        .gform_required_legend {
            font-size: 1.4rem;
        }

        .ginput_left,
        .ginput_right {
            width: 100%;
        }

        .gfield {
            //grid-column: span 6;

            /*&.inline {
                grid-column: 1 / -1;

                .gfield_label {
                    float: left;
                    margin-top: 0.5rem;
                }

                .ginput_container_radio {
                    float: left;
                    margin-top: 0;
                    margin-left: 2rem;
                }
            }

            &.large {
                grid-column: span 8;
            }

            &.small {
                grid-column: span 4;
            }*/

            /*&.wrap {
                grid-column: 1/ span 6;
            }

            &.full {
                grid-column: 1 / -1;
            }*/
        }

        .gfield_label {
            @include SuisseIntlBook(1.6rem, 2rem);
            font-weight: normal;
        }

        .ginput_container {
            margin-top: 1rem;
        }

        .ginput_complex {
            gap: 4rem;
            //margin-bottom: 4rem;

            &.ginput_container_address {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-row-gap: 2rem;
                margin-bottom: 0;
            }

            label {
                margin-top: 1rem;
            }
        }

        .ginput_container {

            > span {

                input {
                    height: 5rem;
                    background-color: white;
                    border: 1px solid #DFDFDF;
                    @include SuisseIntlBook(1.6rem, 1.8rem);
                    padding: 0 2rem;
                }

                select {
                    cursor: pointer;
                    height: 5rem;
                    background-color: white;
                    padding: 0 2rem;
                    width: 100%;
                    @include SuisseIntlBook(1.6rem, 1.8rem);
                }
            }
        }

        .ginput_container_date,
        .ginput_container_phone,
        .ginput_container_email,
        .ginput_container_text {
            height: 5rem;
            background-color: white;
            border: 1px solid #DFDFDF;
            @include SuisseIntlBook(1.6rem, 1.8rem);

            input {
                width: 100%;
                height: 100%;
                padding: 0 2rem;
            }
        }

        .ginput_container_select {
            position: relative;

            &:before {
                pointer-events: none;
                position: absolute;
                content: "";
                top: 0;
                right: 0;
                z-index: 2;
                width: 5rem;
                height: 5rem;
                background: transparent url('../images/common/arrow-down-small.png') no-repeat center center;
                background-size: 14px 8px;
                border-left: 1px solid #DFDFDF;
            }

            select {
                cursor: pointer;
                height: 5rem;
                background-color: white;
                border: 1px solid #DFDFDF;
                padding: 0 2rem;
                width: 100%;
                @include SuisseIntlBook(1.6rem, 1.8rem);
            }
        }

        .ginput_container_textarea {
            height: 24rem;
            background-color: white;
            border: 1px solid #DFDFDF;
            @include SuisseIntlBook(1.6rem, 1.8rem);

            textarea {
                resize: none;
                width: 100%;
                height: 100%;
                padding: 2rem;
            }
        }

        .gfield_description {
            font-size: 1.2rem;
            line-height: 1.4;
            margin-top: 2rem;
            opacity: 0.8;
            font-style: italic;
        }

        .ginput_container_fileupload {
            margin-top: 2rem !important;
        }

        .gfield_radio {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 4rem;
            grid-row-gap: 2rem;
            align-items: center;

            .gchoice {
                margin-left: 0;

                &:first-child {
                    margin-left: 0;
                }
            }

            label {
                @include SuisseIntlBook(1.4rem, 2rem);
                margin-left: 0.6rem;
            }
        }

        .ginput_container_checkbox {
            margin-top: 2rem;

            .gchoice {
                display: flex;
                margin-top: 1rem;

                &:first-child {
                    margin-top: 0;
                }
            }

            input {
                flex: 0 0 auto;
            }

            label {
                flex: 1 1 auto;
                @include SuisseIntlBook(1.2rem, 1.8rem);
                margin-left: 2rem;
            }
        }

        .ginput_container_fileupload {
            margin-top: 0;
            //height: 5rem;
            width: 21.4rem;
            position: relative;

            .gform_fileupload_multifile {
                /*position: absolute;
                right: 0;
                transform: translate(100%, 0);
                top: 0;
                height: 5rem;*/
                padding-top: 5.4rem;

                + .gform_fileupload_rules {
                    padding: 2rem !important;
                    height: auto;
                }
            }

            html.desktop & {
                &:hover {
                    &:before {
                        background-color: $red-3;
                    }
                }
            }

            &:before {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 0;
                content: "Joindre un fichier";
                background-color: $red-1;
                padding: 1.8rem 0;
                text-align: center;
                width: 16rem;
                color: white;
                @include SuisseIntlSemiBold(1.4rem, 1.4rem);
                transition: background-color 0.6s $easeOutQuad;
            }

            html:lang(en-US) & {
                &:before {
                    content: "Attach a file";
                }
            }


            &:after {
                pointer-events: none;
                position: absolute;
                left: 16.1rem;
                top: 0;
                content: "";
                height: 5rem;
                background: $red-1 url("../images/common/upload.png") no-repeat center center;
                background-size: 11px 15px;
                width: 5rem;
                z-index: 2;
            }

            input[type="file"] {
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0.00001;
            }

            .gform_fileupload_rules {
                pointer-events: none;
                padding-top: 6rem;
                font-size: 1.2rem;
            }
        }

        .gfield_html {
            @include SuisseIntlBook(1.2rem, 1.8rem);
            padding-left: 3.2rem;

            a {
                color: $red-1;

                html.desktop & {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .gform_footer {
            justify-content: flex-end;
            margin-top: 4rem;

            input {
                background-color: $red-1;
                color: white;
                @include SuisseIntlSemiBold(1.6rem, 1.6rem);
                height: 5rem;
                display: flex;
                align-items: center;
                padding: 0 3rem;
                transition: background-color 0.6s $easeOutQuad;

                html.desktop & {
                    &:hover {
                        background-color: $red-3;
                    }
                }
            }

            .gform_ajax_spinner {
                position: absolute;
                right: -3rem;
                top: 3.2rem;
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .form__head {
            height: auto;
            padding-bottom: 7rem;

            .pattern {
                width: 30rem;
                height: 30rem;
            }
        }

        .form__content {
            margin-top: 0;

            .grid {
                width: 100%;
            }
        }

        .gform_wrapper {
            padding: 3rem;

            &.gravity-theme {

                .gform_fields {
                    grid-row-gap: 2rem;
                }
            }
        }

        .gform_confirmation_wrapper {
            padding: 3rem 3rem 0 3rem;
            text-align: center;
        }

        .gfield {
            grid-column: 1 / -1 !important;
        }
    }

}