.block-slider {

    &[data-block-slider-type="free"] {
        .c-1 {
            .text-content {
                h2,
                h3 {
                    color: $blue-1;
                }
            }
        }

        .c-2 {
            .realisation-item .thumbnail:after {
                display: none;
            }
        }
    }

    .grid {
        z-index: 1;
        margin-top: 0;
    }

    .c-1 {
        z-index: 12;

        .filters-container {
            margin-top: 6rem;
            z-index: 4;
        }

        .button-rect,
        .text-content {
            margin-top: 4rem;
        }

        .text-content {

            h2,
            h3 {
                color: white;
            }

            ul,
            p {
                opacity: 0.7;
            }
        }

        .text-content {
            position: relative;
        }

        .text-item {
            display: none;
            @include autoAlpha(0);
            transform: translate(0, 10px);
            /*position: absolute;
            top: 0;
            left: 0;*/
            width: 100%;
        }
    }

    .c-2 {
        @include autoAlpha(0);
        transform: translate(0, 10px);
        margin-left: auto;
        min-height: 57rem;
    }

    .tools {
        display: flex;
        align-items: center;
        @include SuisseIntlSemiBold(1.4rem, 1.4rem);

        > div {
            margin-left: 3rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .counter {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        color: $blue-1;

        .line {
            width: 0.6rem;
            height: 1px;
            background-color: $blue-1;
            margin: 0 1.4rem;
        }
    }

    .progress {
        flex: 1 1 auto;
        background-color: rgba(0, 24, 51, 0.09);
        height: 1px;
        width: 100%;

        .progress__line {
            width: 100%;
            height: 1px;
            background-color: $red-1;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
        }
    }

    .arrows {
        flex: 0 0 auto;

        .prev,
        .next {
            border-color: rgba(170, 170, 170, 0.3);

            svg {
                stroke: $blue-1;
            }
        }
    }

    .swiper {
        margin-top: 6rem;
        width: 40rem;
        overflow: visible;
        margin-left: 0;

        &:before {
            content: "";
            background-color: white;
            height: 150%;
            width: 100vw;
            left: 0;
            top: 0;
            position: absolute;
            z-index: 10;
            transform: translate(-101%, -25%);
        }
    }

    .pattern {
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .bg-pattern__grey;
        width: 80rem;
        height: 80rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: white;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    &.blue {
        color: white;

        h4,
        h3,
        .text-content {
            color: white
        }

        .counter {
            color: white;

            .line {
                background-color: white;
            }
        }

        .progress {
            background-color: rgba(255, 255, 255, 0.3);

            .progress__line {
                background-color: white;
            }
        }

        .arrows {

            .prev,
            .next {
                border-color: white;

                svg {
                    stroke: white;
                }
            }
        }

        .swiper {

            &:before {
                background-color: $blue-1;
            }
        }

        .pattern {
            @extend .bg-pattern__blue;

            &:before {
                background-color: $blue-1;
            }
        }
    }

    .loader {
        @include autoAlpha(0);
        @include full-absolute;
        background-color: rgba(0, 24, 51, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        img {
            width: 8rem;
            height: auto;
        }
    }

    @media screen and (max-width: $mobile) {

        .c-1 {

            .filters-container {
                margin-top: 3rem;
            }
        }

        .c-2 {
            margin-top: 4rem;
            min-height: 47rem;
        }

        .tools {
            .arrows {
                display: none;
            }
        }

        .swiper {
            margin-top: 4rem;
            width: 100%;

            &:before {
                display: none;
            }
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            @extend .bg-pattern__grey;
            width: 100%;
            height: 30rem;
            z-index: 0;
            pointer-events: none;
        }
    }
}