.block-news {
    background-color: $blue-1;

    .grid {
        z-index: 2;
    }

    .r-1 {
        margin-bottom: 4rem;
    }

    .r-2 {
        @include SuisseIntlSemiBold(3.8rem, 4.4rem);
        color: white;
    }

    .r-3 {
        margin-top: 6rem;
        align-items: stretch;
    }

    .r-4 {
        margin-top: 5rem;
        justify-content: flex-end;
    }

    .new-item {
        position: relative;
        @include set-grid-prop(margin-left, 1, 0);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        &:first-child {
            margin-left: 0;
        }

        a {
            display: block;

            html.desktop & {

                &:hover {

                    .thumbnail {

                        img {
                            transform: scale(1);
                        }

                        .over {
                            svg {
                                transform: translate(4px, 0);
                            }
                        }

                    }
                }
            }
        }

        .thumbnail {
            height: 26rem;
            width: 100%;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transform: scale(1.1);
                transition: transform 1.2s $easeInOutQuart;
            }

            .over {
                @include full-absolute;
                background: rgb(0,24,51);
                background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51, 0.4) 100%);
                z-index: 10;

                .circle {
                    position: absolute;
                    right: 3rem;
                    bottom: 3rem;
                    width: 5rem;
                    height: 5rem;
                    border: 1px solid white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 2rem;
                        height: auto;
                        fill: white;
                        transition: transform 0.6s $easeInOutQuart;
                    }
                }
            }
        }

        .metas {
            margin-top: 2rem;
            color: white;
            padding-bottom: 3rem;

            .cat {
                @include SuisseIntlSemiBold(1.2rem, 1.2rem);

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        margin-left: 1rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            .title {
                margin-top: 1rem;
                @include SuisseIntlSemiBold(2.2rem, 2.8rem);
            }
        }
    }

    .pattern {
        z-index: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .bg-pattern__blue;
        width: 70rem;
        height: 70rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $blue-1;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-1 {
            margin-bottom: 3rem;
        }

        .r-2 {
            font-size: 2.8rem;
            line-height: 1.2;
        }

        .r-3 {
            margin-top: 4rem;
        }

        .new-item {
            margin-top: 5rem;

            &:first-child {
                margin-top: 0;
            }

            .thumbnail {
                height: 23rem;
            }

            .metas {
                .title {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                }
            }
        }

        .pattern {
            width: 30rem;
            height: 30rem;
        }
    }
}