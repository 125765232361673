.block-jobs {
    background-color: $red-1;

    .grid {
        z-index: 4;
    }

    .r-1 {
        .up-title {
            color: white;
        }
    }

    .r-2 {
        margin-top: 10rem;

        .column-10 {
            @include set-grid-prop(margin-left, 2, 2);
        }

        .swiper__progress {
            position: absolute;
            top: 0;
            left: -6rem;
            height: 30rem;
            width: 2px;
            background-color: rgba(255, 255, 255, 0.3);

            .progress__line {
                height: 100%;
                width: 100%;
                background-color: white;
                transform: scaleY(0);
                transform-origin: 0 0;
                transition: transform 0.6s $easeInOutQuart;
            }
        }

        .list-jobs {
            height: 30rem;

            .swiper-slide {
                height: 6rem;
                color: white;
                @include SuisseIntlLight(4.4rem, 5.2rem);

                a {
                    transform: translate(-6rem, 0);
                    transition: transform 0.8s $easeInOutQuart, opacity 0.8s $easeOutQuart;
                    opacity: 0.6;
                }

                &.is-enabled {
                    a {
                        opacity: 1;
                        transform: translate(0, 0);
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    background-color: $red-1;
                    width: 100%;
                    left: 0;
                    top: 0;
                    height: 100%;
                    transform: translate(-100%, 0);
                }

                a {
                    display: flex;
                    align-items: center;
                }

                svg {
                    width: 4rem;
                    fill: white;
                }

                .text {
                    margin-left: 2rem;
                }
            }
        }

        .arrows {
            margin-top: 3rem;

            .prev,
            .next {
                border-color: rgba(255, 255, 255, 0.3);
                padding: 0;
                width: 8.6rem;
                height: 8.6rem;

                svg {
                    width: 1.4rem;
                }

                html.desktop & {

                    &:hover {
                        background-color: white;
                        border-color: white;

                        svg {
                            stroke: $red-1;
                        }
                    }
                }
            }

            .prev {
                svg {
                    transform: rotate(-90deg);
                }
            }

            .next {
                padding-top: 0.4rem;

                svg {
                    transform: rotate(90deg);
                }
            }
        }

        .job-item {
            border-top: 1px solid $red-3;
            padding: 2rem 3rem;

            &:last-child {
                border-bottom: 1px solid $red-3;
            }

            a {
                display: flex;
                align-items: center;
            }

            .title {
                @include SuisseIntlLight(3.2rem, 3.8rem);
                color: white;
                margin-right: 1rem;
            }

            .button-arrow-circle {
                flex: 0 0 auto;
                border-color: white;
            }
        }

        .button-rect {
            margin: 6rem 3rem 0 3rem;
            width: calc(100% - 6rem);
        }
    }

    .pattern {
        pointer-events: none;
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .bg-pattern__red;
        width: 80rem;
        height: 80rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $red-1;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    .images {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 40rem;
        z-index: 2;

        .swiper-slide {
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                transform-origin: 100% 50%;
                transform: scale(0.9);
                transition: transform 0.8s $easeInOutQuart;
            }

            &:after {
                display: none;
                @include full-absolute;
                content: "";
                background: rgb(0,24,51);
                background: linear-gradient(270deg, rgba(0,24,51,0) 0%, rgba(0,24,51,1) 100%);
                z-index: 10;
                pointer-events: none;
                opacity: 0.3;
            }

            &.swiper-slide-active {
                img {
                    transform: scale(1);
                }
            }
        }
    }
    
    @media screen and (max-width: $mobile) {

        .grid {
            width: 100%;
        }

        .r-1 {
            padding: 0 3rem;
        }

        .r-2 {
            margin-top: 5rem;
        }

        .pattern {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 30rem;
            z-index: 0;
            pointer-events: none;
        }
    }

}