.block-certifications {
    @extend .bg-pattern__grey;

    &:before {
        content: "";
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translate(-15%, 0) skewX(-40deg);
    }

    .grid {
        z-index: 3;
    }

    .c-1 {
        .button-rect {
            margin-top: 3rem;
        }

        color: $blue-3;
    }

    .c-2 {
        margin-left: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;

        .logo-item {
            background-color: white;
            height: 18rem;
            box-shadow: 0 12px 24px 0 rgba(0, 24, 51, 0.16);
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }

            img {
                max-width: 80%;
                height: auto;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        background: transparent;

        &:before {
            display:  none;
        }

        .c-2 {
            margin-top: 4rem;
            grid-template-columns: repeat(2, 1fr);

            .logo-item {
                height: 15rem;
            }
        }
    }
}