.block-accordion {

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .accordion-item {
        //margin-top: 3rem;

        &:first-child {
            margin-top: 0;
        }

        .accordion-item__topbar {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 3rem 0;
            border-top: 1px solid rgba(0, 24, 51, 0.2);

            .left {
                color: $blue-1;
                @include SuisseIntlSemiBold(2.4rem, 3.2rem);

                img {
                    display: block;
                }
            }

            .right {
                margin-left: auto;
                padding-right: 2rem;

                svg {
                    width: 2rem;
                    height: auto;
                }
            }
        }

        .accordion-item__content {
            position: relative;
            overflow: hidden;
            height: 0;

            .accordion-item__wrapper {
                display: flex;
                align-items: center;
                padding-bottom: 4rem;

                .image {
                    flex: 0 0 auto;
                    width: 43rem;
                    margin-right: 4rem;

                    img {
                        object-fit: contain;
                        width: 100%;
                        height: auto;
                    }
                }

                .button-rect {
                    margin-top: 4rem;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .accordion-item {

            .accordion-item__topbar {

                .left {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    padding-right: 4rem;

                    img {
                        max-width: 100%;
                    }
                }
            }

            .accordion-item__content {
                .accordion-item__wrapper {
                    flex-direction: column;

                    .image {
                        width: 100%;
                        height: auto;
                        margin-bottom: 4rem;
                        margin-right: 0;

                        img {
                            object-fit: unset;
                            width: auto;
                            height: auto;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}