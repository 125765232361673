.block-images-slider {

    .swiper {
        width: 100%;
        margin: 0 auto;
    }

    .swiper-slide {
        width: 100%;
        height: auto;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .tools {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        @include SuisseIntlSemiBold(1.4rem, 1.4rem);

        > div {
            margin-left: 3rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .counter {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        color: $blue-1;

        .line {
            width: 0.6rem;
            height: 1px;
            background-color: $blue-1;
            margin: 0 1.4rem;
        }
    }

    .progress {
        flex: 1 1 auto;
        background-color: rgba(0, 24, 51, 0.09);
        height: 1px;
        width: 100%;

        .progress__line {
            width: 100%;
            height: 1px;
            background-color: $red-1;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
        }
    }

    .arrows {
        flex: 0 0 auto;

        .prev,
        .next {
            border-color: rgba(170, 170, 170, 0.3);

            svg {
                stroke: $blue-1;
            }
        }
    }
}