.block-realisations {
    padding: 0 !important;
    background-color: $blue-1;
    border-top: none;

    .head-background {
        height: 60rem;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:after {
            content: "";
            @include full-absolute;
            background: rgb(0,24,51);
            background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51,0.8) 100%);
            z-index: 2;
        }
    }

    .grid {
        position: relative;
        z-index: 2;
        margin-top: -16rem;
    }

    .r-1 {

        .column-11 {
            @include set-grid-prop(margin-left, 0, 1);

            h1 {
                @include SuisseIntlSemiBold(11rem, 12.4rem);
                color: white;

                span {
                    display: inline-block;
                }

                .l-2 {
                    margin-left: 15rem;
                }
            }
        }
    }

    .r-2 {
        margin-top: 4rem;

        .column-12 {
            display: flex;
            justify-content: flex-end;
        }
    }

    .block-slider {
        padding: 12rem 0 12rem 0;
        border: none;
    }

    @media screen and (max-width: $mobile) {

        .head-background {
            height: 30rem;
        }

        .grid {
            margin-top: -4rem;
        }

        .r-1 {

            .column-11 {

                h1 {
                    font-size: 3rem;
                    line-height: 3.6rem;

                    .l-2 {
                        margin-left: 6rem;
                    }
                }
            }
        }

        .block-slider {
            padding: 6rem 0;
        }
    }

}