.block-approach {
    z-index: 2;

    .swiper {
        overflow: visible;
        height: 24rem;

        .swiper-wrapper {
            align-items: stretch;
        }

        .swiper-slide {
            box-sizing: border-box;
            position: relative;
            display: flex;
            box-shadow: 0 12px 24px 0 rgba(0, 24, 51, 0.16);
            padding: 3rem 2rem;
            flex-direction: column;
            height: 24rem;
            overflow: hidden;
            background-color: white;

            &.has-over {
                cursor: pointer;
            }

            .icon {
                margin-bottom: 4rem;
            }

            .title {
                @include SuisseIntlSemiBold(1.8rem, 2.6rem);
                color: $blue-1;
                margin-top: auto;
            }

            .over {
                background-color: white;
                @include full-absolute;
                z-index: 2;
                @include SuisseIntlBook(1.6rem, 2.4rem);
                padding: 3rem 2rem;
                color: $blue-2;
                opacity: 0;
                transform: translate(0, 6px);
                transition: transform 0.6s $easeOutQuart, opacity 0.6s $easeOutQuart;
            }

            &:before {
                z-index: 3;
                content: "";
                background-color: $red-1;
                height: 0.6rem;
                width: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }

            html.desktop & {
                &:hover {
                    .over {
                        opacity: 1;
                        transform: translate(0, 0) rotate(0.01deg);
                    }
                }
            }
        }
    }

    + .block-realisations {
        z-index: 1;
        margin-top: -19rem;
    }

    @media screen and (max-width: $mobile) {
        + .block-realisations {
            z-index: 1;
            margin-top: -10rem;
        }
    }

}