.block-key-numbers {
    @extend .bg-pattern__grey;
    //padding: 10rem 0;

    &:before {
        content: "";
        position: absolute;
        background-color: white;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translate(-15%, 0) skewX(-40deg);
    }

    .grid {
        z-index: 2;
    }

    .r-1  {
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(calc((100% - 6rem) / 4), 1fr) );
        grid-row-gap: 6rem;
        grid-column-gap: 2rem;
    }

    .key-item {

        .key-item__top {
            display: flex;
            align-items: flex-end;
            padding-bottom: 1rem;
            border-bottom: 2px solid #EDEDED;
        }

        .key-item__legend {
            margin-top: 1rem;
            @include SuisseIntlBook(1.4rem, 1.6rem);
        }

        .nb {
            @include SuisseIntlLight(7.2rem, 6.8rem);
            color: $blue-1;
        }

        .sx {
            @include SuisseIntlLight(5rem, 6rem);
            color: $blue-1;
        }
    }

    @media screen and (max-width: $mobile) {
        background: none;

        &:before {
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            width: 150%;
            height: 30rem;
            transform: translate(-50%, 0) skewX(-40deg);
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30rem;
            background: transparent url('../images/patterns/grey.png') repeat left top;
            background-size: 390px 390px;
        }

        .r-1 {
            grid-template-columns: 1fr;
            grid-row-gap: 4rem;
        }
    }
}