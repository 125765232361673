.block-contact {

    .column-11,
    .column-9 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-2 {
        margin-top: 9rem;

        .column-9 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 5rem;
            grid-column-gap: 10rem;
        }
    }

    .contact-item {

        &:first-child {
            margin-left: 0;
        }

        .name {
            @include SuisseIntlSemiBold(2.4rem, 2.8rem);
            color: $blue-1;
        }

        .function {
            @include SuisseIntlBook(1.6rem, 2.2rem);
            color: $blue-2;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            margin-top: 4rem;

            .column-9 {
                grid-template-columns: repeat(1, 1fr);
                grid-column-gap: 2rem;
            }
        }
    }

}