#search {

    .search__head {
        background-color: white;
        height: 50rem;
        border-bottom: 1px solid #EDEDED;
        padding-bottom: 10rem;

        .grid {
            padding-top: 24rem;
            z-index: 2;
        }

        .r-2 {
            margin-top: 3rem;

            form {
                width: 100%;
                display: flex;
                align-items: center;

                .input {
                    width: calc(100% - 10rem);
                    height: 10rem;
                    background-color: $grey;

                    input {
                        @include SuisseIntlLight(6.4rem, 6.8rem);
                        color: $blue-1;
                        height: 100%;
                        width: 100%;
                        padding: 0 2rem;
                    }
                }

                .icon {
                    flex: 0 0 auto;
                    height: 10rem;
                    width: 10rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $grey;

                    svg {
                        width: 1.8rem;
                        height: 1.8rem;
                        fill: $blue-1;
                    }
                }
            }
        }

        .pattern {
            z-index: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            bottom: 0;
            @extend .bg-pattern__grey;
            width: 50rem;
            height: 50rem;
            z-index: 0;
            pointer-events: none;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: white;
                width: 200%;
                height: 100%;
                transform: translate(-100%, -50%) skewX(-45deg);
                z-index: 2;
            }
        }
    }

    .search__content {
        padding: 8rem 0;

        .column-10 {
            @include set-grid-prop(margin-left, 1, 1);
        }

        .post-item {
            border-top: 1px solid rgba(0, 24, 51, 0.2);

            &:last-child {
                border-bottom: 1px solid rgba(0, 24, 51, 0.2);
            }

            a {
                padding: 4rem 0;
                display: flex;
                align-items: center;

                html.desktop & {
                    &:hover {
                        svg {
                            transform: translate(6px, 0);
                        }
                    }
                }
            }

            .type {
                padding: 0.98rem 1rem 0.8rem 1rem;
                background-color: $grey;
                text-transform: uppercase;
                @include SuisseIntlSemiBold(1rem, 1rem);
                color: $blue-1;
            }

            .title {
                margin-left: 2rem;
                @include SuisseIntlSemiBold(2.4rem, 3.2rem);
                color: $blue-1;
            }

            svg {
                margin-left: auto;
                width: 2.6rem;
                height: auto;
                transition: transform 0.6s $easeInOutQuart;
            }
        }

        .no-result {
            text-align: center;
        }
    }

    @media screen and (max-width: $mobile) {

        .search__head {
            height: auto;
            padding-bottom: 0;

            .grid {
                width: 100%;
            }

            .r-1 {
                padding: 0 3rem;
            }

            .r-2 {

                form {
                    width: 100%;

                    .input {
                        height: 8rem;
                        width: calc(100% - 8rem);

                        input {
                            font-size: 3.2rem;
                            line-height: 1.2;
                        }
                    }

                    .icon {
                        width: 8rem;
                        height: 8rem;
                    }
                }
            }

            .grid {
                padding-top: 15rem;
            }

            .pattern {
                width: 30rem;
                height: 30rem;
            }
        }

        .search__content {
            padding: 6rem 0;

            .post-item {

                a {
                    padding: 2rem 0;
                    flex-wrap: wrap;

                    .title {
                        margin-left: 0;
                        margin-top: 1rem;
                        width: calc(100% - 4rem);
                    }
                }
            }
        }
    }

}