.block-portraits {

    .r-1 {
        .c-1 {
            @include set-grid-prop(margin-left, 1, 1);
        }
    }

    .r-2 {
        margin-top: 6rem;

        .c-1 {
            color: $blue-1;
            @include set-grid-prop(margin-left, 1, 1);
        }

        .c-2 {
            margin-left: auto;
            @include set-grid-prop(margin-right, 1, 1);

            .text-content {
                color: $blue-3;
            }
        }
    }

    .r-3 {
        margin-top: 4rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;

        .portrait-item {
            margin-top: 4rem;
            width: 100% !important;

            .portrait-item__thumbnail {
                position: relative;
                height: 35rem;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .portrait-item__metas {
                margin-top: 2rem;

                .name {
                    @include SuisseIntlSemiBold(1.6rem, 1.8rem);
                    color: $blue-1;
                }

                .function {
                    margin-top: 0.6rem;
                    @include SuisseIntlBook(1.4rem, 1.6rem);
                    color: $blue-1;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            margin-top: 4rem;

            .c-2 {
                margin-top: 3rem;
            }
        }

        .r-3 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;

            .portrait-item {
                margin: 0;

                .portrait-item__thumbnail {
                    height: 20rem;
                }

                .portrait-item__metas {

                    .name {
                        font-size: 1.6rem;
                        line-height: 1.2;
                    }

                    .function {
                        font-size: 1.4rem;
                        line-height: 1.2;
                    }
                }
            }
        }
    }

}