#header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: transform 1.2s $easeInOutQuart;
    transform: translate(0, -100%);

    &.visible {
        transform: translate(0, 0);
    }

    .header__top {
        //background-color: $blue-1;
        height: 10.4rem;
        display: flex;
        align-items: center;
        padding-left: 5rem;
        color: white;
        transition: color 0.6s $easeOutQuart 0.1s;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: white;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            pointer-events: none;
            transform-origin: 0 0;
            transform: scaleY(0);
            transition: transform 0.6s $easeInOutQuart;
        }

        &.white {
            color: $blue-1;

            &:after {
                opacity: 1;
                background-color: #EDEDED;
            }

            &:before {
                transform: scaleY(1);
            }

            .logo {
                svg.color {
                    opacity: 1;
                }
            }

            .lang .current svg {
                stroke: $blue-1;
            }

            .search {
                border-left-color: #EDEDED;

                svg {
                    fill: $blue-1;
                }
            }

            .header__top-mobile {
                .line {
                    background-color: $blue-1;
                }
            }
        }

        &:after {
            content: "";
            background-color: white;
            opacity: 0.3;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            transition: opacity 0.6s $easeOutQuart 0.2s;
        }

        .header__top-left {
            position: relative;
            height: 100%;
            z-index: 2;
        }

        .header__top-right {
            position: relative;
            height: 100%;
            margin-left: auto;
            display: flex;
            align-items: stretch;
            z-index: 2;
        }

        .header__top-mobile {
            display: none;
        }
    }

    .logo {
        height: 100%;
        display: flex;
        align-items: center;

        a {
            display: block;

            svg {
                width: 20rem;
                height: auto;

                &.color {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity 0.6s $easeOutQuart;
                }
            }
        }
    }

    #menu {
        flex: 0 0 auto;
        height: 100%;

        > ul {
            height: 100%;
            display: flex;
            align-items: center;

            > li:not(.button) {
                cursor: pointer;
                height: 100%;
                margin-left: 4rem;

                > a {
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    @include SuisseIntlBook(1.4rem, 1.6rem);

                    &:before {
                        content: "";
                        width: 100%;
                        height: 0.4rem;
                        background-color: $red-1;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: 0 0;
                        transition: transform 0.6s $easeInOutQuart;
                    }
                }

                html.desktop & {
                    &:hover {
                        a {
                            &:before {
                                transform: scaleX(1);
                            }
                        }

                        .header__submenu {
                            //display: block;
                            left: 0;

                            .header__submenu-inner {
                                opacity: 1;
                            }

                            .grid {
                                opacity: 1;
                                transform: translate(0, 0) rotate(0.001deg);
                            }

                            .video {
                                opacity: 1;
                            }
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &.disabled {
                    > a {
                        pointer-events: none;
                    }
                }
            }

            li.button {
                height: 100%;
                margin-left: 4rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                color: white;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    background-color: $red-1;
                    padding: 1.6rem 3rem;
                    border: 1px solid $red-1;

                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background-color: $red-3;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: scaleX(0);
                        transform-origin: 0 0;
                        transition: transform 0.6s $easeInOutQuart;
                        z-index: 1;
                    }

                    span {
                        position: relative;
                        z-index: 2;
                        @include SuisseIntlBook(1.4rem, 1.6rem);
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    .header__submenu {
        position: fixed;
        top: 10.4rem;
        left: 200vw;
        width: 100%;
        z-index: 2;

        .header__submenu-inner {
            position: relative;
            opacity: 0;
            background-color: white;
            transition: opacity 0.6s $easeOutQuart;

            &:after {
                content: "";
                background-color: $grey;
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }

        html.desktop & {
            &:hover {
                left: 0;
            }
        }

        .grid {
            padding: 6rem 0;
            opacity: 0;
            transform: translate(0, 10px);
            transition: opacity 0.6s $easeOutQuart 0.1s, transform 0.6s $easeOutQuart 0.1s;

            .column-3 {
                h4 {
                    color: $blue-1;
                }

                .description {
                    margin-top: 1.4rem;
                    @include SuisseIntlSemiBold(1.4rem, 2.2rem);
                    padding-right: 1rem;
                    color: $blue-3;
                }
            }

            .column-6 {
                @include set-grid-prop(margin-left, 2, 1);

                ul {
                    display: grid;
                    grid-gap: 4rem 6rem;
                    grid-auto-rows: minmax(min-content, max-content);
                    grid-template-columns: repeat(2, 1fr);

                    li {
                        cursor: pointer;

                        a {
                            height: 100%;
                            position: relative;
                            display: block;
                            @include SuisseIntlSemiBold(1.6rem, 2.4rem);
                            color: $blue-1;
                            padding-bottom: 3rem;

                            &:before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                width: 100%;
                                background-color: $grey;
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                height: 2px;
                                width: 100%;
                                background-color: $red-1;
                                transform-origin: 0 0;
                                transform: scaleX(0);
                                transition: transform 0.6s $easeInOutQuart;
                                z-index: 2;
                            }

                            html.desktop & {
                                &:hover {
                                    &:after {
                                        transform: scaleX(1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .video {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 28.8rem;
            background-color: $red-1;
            opacity: 0;
            transition: opacity 0.6s $easeOutQuart;
            z-index: 2;

            a {
                display: block;
                height: 100%;
            }

            .thumb {
                position: relative;
                height: 18rem;
                width: 100%;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .button-play {
                    @include center-xy;
                    width: 5.6rem;
                    height: 5.6rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;

                    &:before {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        content: "";
                        border: 2px solid white;
                        border-radius: 50%;
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    svg {
                        width: 1rem;
                        height: auto;
                    }
                }
            }

            .title {
                padding: 2rem;
                @include SuisseIntlSemiBold(1.6rem, 2.4rem);
                color: white;

                svg {
                    display: inline-block;
                    margin-left: 0.8rem;
                    width: 1.8rem;
                    height: auto;
                    transition: transform 0.6s $easeInOutQuart;
                }
            }

            html.desktop & {

                &:hover {

                    .thumb {
                        .button-play {
                            &:before {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .title {

                        svg {
                            transform: translate(4px, 0);
                        }
                    }
                }
            }
        }
    }

    .lang {
        cursor: pointer;
        flex: 0 0 auto;
        margin-left: 4rem;
        display: flex;
        padding: 0 1rem;
        align-items: center;

        .current {
            position: relative;
            display: flex;
            align-items: center;
            @include SuisseIntlSemiBold(1.2rem, 1.2rem);
            letter-spacing: 0.86px;
            text-transform: uppercase;
            z-index: 2;
            transition: color 0.6s $easeOutQuart;

            svg {
                margin-left: 1.4rem;
                width: 0.8rem;
                height: auto;
                stroke: white;
                transition: stroke 0.6s $easeOutQuart;
            }
        }

        .list {
            @include autoAlpha(0);
            position: absolute;
            bottom: 0;
            background-color: $red-1;
            width: 100%;
            left: 0;
            z-index: 1;
            transition: opacity 0.6s $easeOutQuart;
            color: white;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 4rem;
                background-color: $red-1;
                left: 0;
                top: 0;
                transform: translate(0, -98%);
            }

            li {
                @include SuisseIntlSemiBold(1.2rem, 1.2rem);
                letter-spacing: 0.86px;
                text-transform: uppercase;
                padding: 1rem;
                background-color: $red-1;
                transition: background-color 0.6s $easeOutQuart;

                html.desktop & {
                    &:hover {
                        background-color: $red-3;
                    }
                }
            }
        }

        html.desktop & {
            &:hover {
                .current {
                    color: white;

                    svg {
                        stroke: white;
                    }
                }

                .list {
                    @include autoAlpha(1);
                }
            }
        }
    }

    .search {
        cursor: pointer;
        width: 10rem;
        height: 100%;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        margin-left: 4rem;
        position: relative;
        transition: border-left-color 0.6s $easeOutQuart 0.2s;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: $red-1;
            position: absolute;
            top: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
            z-index: 1;
        }

        svg {
            position: relative;
            z-index: 2;
            width: 1.8rem;
            height: 1.8rem;
            fill: white;
            transition: transform 0.6s $easeInOutQuart, fill 0.6s $easeOutQuart;
        }

        html.desktop & {
            &:hover {
                &:before {
                    transform: scaleX(1);
                }

                svg {
                    transform: scale(1.1);
                    fill: white;
                }
            }
        }

        &.active {
            &:before {
                transform: scaleX(1);
            }

            svg {
                transform: scale(1.1);
                fill: white !important;
            }
        }
    }

    .header__search {
        position: fixed;
        top: 10.4rem;
        height: 0;
        overflow: hidden;
        width: 100%;
        z-index: 10;

        .header__search-inner {
            position: relative;
            width: 100%;
            height: 11rem;
            background-color: white;
            padding: 0 5rem;
            border-bottom: 1px solid $grey;

            form {
                height: 100%;
                display: flex;
                align-items: center;
                opacity: 0;
                transform: translate(0, -10px);

                .icon {
                    flex: 0 0 auto;

                    svg {
                        fill: $blue-1;
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                }

                .input {
                    margin-left: 5rem;
                    border-left: 1px solid $blue-1;
                    height: 5rem;
                    padding-right: 5rem;
                    flex: 2 1 0;

                    input {
                        padding-left: 2rem;
                        @include SuisseIntlSemiBold(2.4rem, 5rem);
                        color: $blue-1;
                        width: 100%;
                    }
                }

                .close {
                    flex: 0 0 auto;
                    cursor: pointer;
                    margin-left: auto;

                    svg {
                        fill: $blue-1;
                        width: 2.2rem;
                        height: 2.2rem;
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {

        .header__top {
            padding: 0 3rem;
            height: 9rem;

            .logo {

                a {

                    svg {
                        width: 18rem;
                    }
                }
            }

            .header__top-right {
                display: none;
            }

            body:not(.version-standalone) & {
                .header__top-mobile {
                    margin-left: auto;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    z-index: 2;

                    .line {
                        height: 2px;
                        width: 3rem;
                        background-color: white;
                        margin-top: 0.6rem;
                        transition: background-color 0.6s $easeOutQuart;

                        &:first-child {
                            margin-top: 0;
                        }

                        &.l-2 {
                            width: 2rem;
                        }

                        &.l-3 {
                            width: 1rem;
                        }
                    }
                }

                &.white {
                    .line {
                        background-color: $blue-1;
                    }
                }
            }
        }
    }

}