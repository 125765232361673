#news {

    .loader {
        @include autoAlpha(0);
        background-color: rgba(0, 24, 51, 0.8);
        @include full-fixed;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 6rem;
            height: auto;
        }
    }

    .pattern {
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        @extend .bg-pattern__grey;
        width: 70rem;
        height: 70rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: white;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    .news-head {

        .column-6 {
            height: 33rem;
            display: flex;
            align-items: flex-end;
        }

        h1 {
            @include SuisseIntlLight();
            color: $blue-1;
        }
    }

    .news-content {
        position: relative;
        z-index: 2;

        .grid {
            max-width: 100%;

            .row:not(.r-3) {
                margin-left: auto;
                margin-right: auto;
                max-width: 1160px;
                width: 100%;
            }
        }

        .new-item {
            position: relative;
            //box-shadow: 0 12px 24px 0 rgba(0, 24, 51, 0.16);

            html.desktop & {
                &:hover {
                    .thumbnail {
                        img {
                            transform: scale(1);
                        }
                    }
                }
            }

            .thumbnail {
                height: 25rem;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transform: scale(1.1);
                    transition: transform 1.2s $easeInOutQuart;
                }
            }

            .date {
                margin-top: 1.8rem;
                @extend .up-title;
            }

            .title {
                margin-top: 1.8rem;
                @include SuisseIntlBook(2.4rem, 3.2rem);
                color: $blue-1;
            }

            .categories {
                margin-top: 1.8rem;
                @include SuisseIntlSemiBold(1.4rem, 1.6rem);
                color: $red-1;

                ul {
                    display: flex;
                    align-items: center;

                    li {
                        margin-left: 1rem;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .r-1 {
            position: relative;
            z-index: 10;
            padding: 6rem 0;

            .icon-mobile {
                display: none;
                height: 7rem;
                background-color: $blue-1;
                padding: 0 3rem;
                width: 100%;
                align-items: center;

                svg {
                    margin-left: auto;
                    width: 2rem;
                    height: auto;
                    opacity: 0.7;
                }

                .text {
                    text-transform: uppercase;
                    color: white;
                    opacity: 0.6;
                    @include SuisseIntlSemiBold(1rem, 1rem);
                    letter-spacing: 0.42em;
                }
            }

            .filters-mobile-background {
                background-color: $blue-1;
                @include full-fixed;
                transform-origin: 0 100%;
                transform: scaleY(0);
                z-index: 0;
            }

            .filters {
                width: 100%;
                display: flex;
                align-items: stretch;
                background-color: $grey;
                height: 8rem;
                flex: 0 0 auto;

                .icon {
                    height: 100%;
                    background-color: $blue-1;
                    padding: 0 3rem;
                    display: flex;
                    align-items: center;

                    svg {
                        width: 2rem;
                        height: auto;
                        opacity: 0.7;
                    }

                    .text {
                        margin-left: 2rem;
                        text-transform: uppercase;
                        color: white;
                        opacity: 0.6;
                        @include SuisseIntlSemiBold(1rem, 1rem);
                        letter-spacing: 0.42em;
                    }
                }

                .filter-item {
                    height: 100%;
                    flex: 0 0 auto;
                    @include SuisseIntlSemiBold(1.4rem, 1.4rem);
                    color: $blue-1;
                    z-index: 2;

                    .filter-item__master {
                        cursor: pointer;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0 2rem;
                        border-right: 1px solid #A8B1BA;

                        svg {
                            width: 1.4rem;
                            height: auto;
                            margin-left: auto;
                        }

                        .nb {
                            margin-left: 1rem;
                            background-color: $red-1;
                            color: white;
                            @include SuisseIntlSemiBold(1rem, 1rem);
                            padding-top: 0.2rem;
                            border-radius: 50%;
                            width: 2rem;
                            height: 2rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0;
                            transition: opacity 0.3s $easeOutQuad;

                            &.show {
                                opacity: 1;
                            }
                        }
                    }

                    .filter-item__list {
                        @include autoAlpha(0);
                        padding: 3rem;
                        background-color: $grey;
                        border: 1px solid #A8B1BA;
                        transition: opacity 0.6s $easeOutQuad;

                        ul {

                            li {
                                margin-top: 2rem;
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                .square {
                                    background-color: white;
                                    border: 1px solid #A8B1BA;
                                    width: 1.6rem;
                                    height: 1.6rem;
                                    flex: 0 0 auto;

                                    &:before {
                                        opacity: 0;
                                        @include center-xy;
                                        content: "";
                                        background-color: $red-1;
                                        width: 1rem;
                                        height: 1rem;
                                        transition: opacity 0.6s $easeOutQuad;
                                    }
                                }

                                .value {
                                    margin-left: 1rem;
                                }

                                &:first-child {
                                    margin-top: 0;
                                }

                                &.selected {
                                    .square {
                                        &:before {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    html.desktop & {
                        &:hover {
                            .filter-item__list {
                                @include autoAlpha(1);
                            }
                        }
                    }
                }

                .tools {
                    margin-left: auto;
                    z-index: 2;
                    flex: 0 0 auto;
                    padding: 0 3rem;
                    display: flex;
                    align-items: center;

                    .button {
                        position: relative;
                        display: flex;
                        align-items: center;
                        padding: 0 2rem;
                        height: 4.6rem;
                        background-color: $red-1;
                        color: white;
                        @include SuisseIntlSemiBold(1.4rem, 1.4rem);
                        cursor: pointer;
                        overflow: hidden;

                        span {
                            z-index: 2;
                        }

                        &:before {
                            content: "";
                            width: 100%;
                            height: 100%;
                            background-color: $red-3;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transform: scaleX(0);
                            transform-origin: 0 0;
                            transition: transform 0.6s $easeInOutQuart;
                            z-index: 1;
                        }

                        html.desktop & {
                            &:hover {
                                &:before {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }

                    .trash {
                        cursor: pointer;
                        margin-left: 2rem;
                        display: flex;
                        align-items: center;

                        svg {
                            width: 1rem;
                            height: auto;
                        }

                        .text {
                            margin-top: 0.2rem;
                            margin-left: 1rem;
                            @include SuisseIntlBook(1.4rem, 1.4rem);
                            color: $blue-1;
                        }
                    }
                }
            }
        }

        .r-2 {
            position: relative;
            overflow: hidden;
            padding-bottom: 10rem;
            flex-direction: column;
            align-items: center;

            .container-news {
                max-width: 1160px;
                width: 100%;
                display: grid;
                grid-column-gap: 3rem;
                grid-row-gap: 3rem;
                grid-template-columns: repeat(3, 1fr);
            }

            .no-result {
                display: none;
                @include SuisseIntlBook(2rem, 2.8rem);
                color: $blue-3;
                text-align: center;
                padding-top: 3rem;
            }
        }

        .r-3 {
            padding-bottom: 10rem;

            .column-12 {
                display: flex;
                justify-content: center;
            }
        }

    }

    @media screen and (max-width: $mobile) {

        .pattern {
            width: 40rem;
            height: 40rem;
        }

        .news-content {

            .grid {
                width: 100%;
            }

            .r-1 {
                padding: 4rem 3rem;

                .icon-mobile {
                    display: flex;
                }

                .filters {
                    display: none;
                    background-color: transparent;
                    @include full-fixed;
                    z-index: 10;
                    flex-direction: column;
                    overflow-y: scroll;
                    padding-bottom: 16rem;
                    padding-top: 14rem;

                    .icon {
                        opacity: 0;
                        position: fixed;
                        background-color: $blue-1;
                        z-index: 10;
                        top: 0;
                        left: 0;
                        width: 100%;
                        flex: 0 0 auto;
                        height: 10rem;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                        &:after {
                            content: "X";
                            @include center-y;
                            right: 3rem;
                            z-index: 10;
                            color: white;
                            @include SuisseIntlLight(3rem, 3rem);
                            transform: translate(0, -50%) scaleY(0.8);
                        }
                    }

                    .filter-item {
                        opacity: 0;
                        color: white;
                        height: auto;

                        .filter-item__master {
                            height: auto;
                            font-size: 1rem;
                            line-height: 1;
                            letter-spacing: 0.46em;
                            padding: 0 3rem;
                            color: rgba(255, 255, 255, 0.7);
                            text-transform: uppercase;
                        }

                        .filter-item__list {
                            background-color: transparent;
                            border: none;
                            padding: 3rem;
                            @include autoAlpha(1);
                        }
                    }

                    .tools {
                        opacity: 0;
                        border-top: 1px solid $blue-1;
                        background-color: $grey;
                        position: fixed;
                        bottom: 0;
                        width: 100%;
                        z-index: 10;
                        flex-direction: column;
                        padding: 3rem;

                        .button {
                            width: 100%;
                            justify-content: center;
                        }

                        .trash {
                            margin-top: 2rem;
                            margin-left: 0;
                        }
                    }
                }
            }

            .r-2 {
                padding: 0 3rem 6rem 3rem;

                .container-news {
                    grid-template-columns: repeat(1, 1fr);
                }

                .no-result {
                    font-size: 1.4rem;
                    line-height: 1.4;
                }
            }
        }
    }

}