form {

}

/**
CHOICES
 */
.choices {

    &.blue {

        .choices__inner {
            display: flex;
            align-items: center;
            background-color: $blue-1;
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 0;
            @include SuisseIntlSemiBold(1.4rem, 1.4rem);
            padding: 0 1.8rem;

            .choices__list--single {
                padding: 0;
            }
        }

        &:after {
            top: 0;
            right: 0;
            margin: 0 !important;
            background: $blue-1 url('../images/common/arrow-down-white.png') no-repeat center center;
            width: 4.4rem;
            height: 100%;
            background-size: 14px 8px;
            border: 1px solid rgba(255, 255, 255, 0.3) !important;
        }

        .choices__list--dropdown, .choices__list[aria-expanded] {
            background-color: $blue-1;
            border-radius: 0;
            border-color: rgba(255, 255, 255, 0.3);

            .choices__item {
                transition: background-color 0.6s $easeOutQuart;
                @include SuisseIntlSemiBold(1.4rem, 1.4rem);

                &.is-highlighted {
                    background-color: darken($blue-1, 5%);
                }
            }
        }
    }
}