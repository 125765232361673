.block-head {
    height: 100vh;
    background-color: $blue-1;
    padding-bottom: 0;

    &.small {
        height: 56rem;

        &.has-anchors {
            height: 70rem;
        }
    }

    .block-background {
        pointer-events: none;
        @include full-absolute;

        &:not(.no-filter) {
            &:after {
                content: "";
                background-color: $blue-1;
                opacity: 0.4;
                @include full-absolute;
                z-index: 2;
            }
        }

        img,
        video {
            @include full-absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        video {
            position: relative;
            z-index: 2;
        }
    }

    .grid,
    .row {
        z-index: 2;
        height: 100%;
    }

    .r-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            color: white;
            font-size: 6rem;
        }

        .subtitle {
            color: white;
            opacity: 0.7;
            margin-top: 2rem;
        }

        .buttons {
            margin-top: 4rem;
            display: flex;
            align-items: center;

            .button-rect {
                display: block;
                margin-left: 2rem;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .metas {
        @include center-y;
        z-index: 10;
        right: 3rem;

        .news {

            .socials {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .socials__legend {
                    color: white;
                    opacity: 0.7;
                    @include SuisseIntlSemiBold(1rem, 1rem);
                    letter-spacing: 0.38em;
                    text-transform: uppercase;
                }

                ul {
                    display: flex;
                    align-items: center;
                    margin-left: 4rem;

                    li {
                        width: 4rem;
                        height: 4rem;
                        margin-left: 1rem;

                        &:first-child {
                            margin-left: 0;
                        }

                        a {
                            border-radius: 50%;
                            border: 1px solid white;
                            overflow: hidden;
                            width: 4rem;
                            height: 4rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            //padding-right: 0.2rem;
                            background-color: transparent;
                            transition: background-color 0.6s $easeOutQuart;

                            svg {
                                height: 1.4rem;
                                width: auto;
                                fill: white;
                                transition: fill 0.6s $easeOutQuart;
                            }

                            html.desktop & {

                                &:hover {
                                    background-color: white;

                                    svg {
                                        fill: $blue-1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .list-news {
                border-left: 1px solid rgba(255, 255, 255, 0.3);
                padding-left: 3rem;
                margin-top: 3rem;

                .container-news {
                    width: 24rem;
                }

                .new-item {
                    width: 24rem;

                    a {

                        html.desktop & {

                            &:hover {

                                .new-item__thumbnail {
                                    img {
                                        transform: scale(1);
                                    }
                                }

                                .new-item__title {
                                    svg {
                                        transform: translate(4px, 0);
                                    }
                                }
                            }
                        }
                    }

                    .new-item__thumbnail {
                        width: 100%;
                        height: 13.8rem;
                        overflow: hidden;
                        background-color: $blue-1;

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            transform: scale(1.1);
                            transition: transform 0.6s $easeInOutQuart;
                        }

                        .button-play {
                            @include center-xy;
                            width: 5rem;
                            height: 5rem;
                            border: 1px solid white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            transition: transform 0.6s $easeInOutQuart;

                            svg {
                                width: 1rem;
                                height: auto;
                                fill: white;
                            }
                        }
                    }

                    .new-item__title {
                        width: 100%;
                        margin-top: 3rem;
                        @include SuisseIntlSemiBold(1.4rem, 2rem);
                        color: white;

                        svg {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        svg {
                            margin-left: 1rem;
                            fill: white;
                            width: 1.7rem;
                            height: auto;
                            transition: transform 0.6s $easeInOutQuart;
                        }
                    }
                }

                .button-inline {
                    margin-top: 2rem;
                }
            }

            .navigation {
                margin-top: 3rem;
                display: flex;
                align-items: center;

                .bullets {
                    pointer-events: none;
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    bottom: auto;
                    left: auto;
                    z-index: 10;

                    span {
                        cursor: pointer;
                        width: 0.8rem;
                        height: 0.8rem;
                        border-radius: 50%;
                        background-color: white;
                        overflow: hidden;
                        margin-left: 0.6rem;

                        &:first-child {
                            margin-left: 0;
                        }

                        &.swiper-pagination-bullet-active {
                            background-color: $red-1 !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }

        .video {
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            padding-left: 3rem;

            a {

                html.desktop & {

                    &:hover {

                        .video__thumbnail {
                            .button-play {
                                transform: translate(-50%, -50%) scale(1.1);
                            }
                        }

                        .video__title {
                            svg {
                                transform: translate(4px, 0);
                            }
                        }
                    }
                }
            }

            .video__thumbnail {
                width: 22.8rem;
                height: 13.8rem;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .button-play {
                    @include center-xy;
                    width: 5rem;
                    height: 5rem;
                    border: 1px solid white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    transition: transform 0.6s $easeInOutQuart;

                    svg {
                        width: 1rem;
                        height: auto;
                        fill: white;
                    }
                }
            }

            .video__title {
                width: 22.8rem;
                margin-top: 3rem;
                @include SuisseIntlSemiBold(1.4rem, 2rem);
                color: white;

                svg {
                    display: inline-block;
                    vertical-align: middle;
                }

                svg {
                    margin-left: 1rem;
                    fill: white;
                    width: 1.7rem;
                    height: auto;
                    transition: transform 0.6s $easeInOutQuart;
                }
            }
        }
    }

    .anchors {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;

        ul {
            display: flex;
            align-items: stretch;

            li {
                border-top: 1px solid rgba(255, 255, 255, 0.3);
                border-left: 1px solid rgba(255, 255, 255, 0.3);
                width: auto;
                position: relative;

                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-color: white;
                    left: 0;
                    top: 0;
                    z-index: 0;
                    transform: scaleX(0);
                    transform-origin: 0 0;
                    transition: transform 0.6s $easeInOutQuart;
                }

                a {
                    width: 100%;
                    display: flex;
                    padding: 3rem;
                    align-items: center;
                }

                /*&:first-child {
                    border-left: none;
                }*/

                .text {
                    @include SuisseIntlSemiBold(1.6rem, 1.6rem);
                    color: white;
                    transition: color 0.6s $easeOutQuart;
                }

                .icon {
                    margin-left: 2rem;
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 3.2rem;
                    height: 3.2rem;
                    padding-right: 0.2rem;
                    transition: border-color 0.6s $easeOutQuart 0.18s;

                    svg {
                        width: 1rem;
                        height: auto;
                        fill: white;
                        transition: transform 0.6s $easeInOutQuart 0.18s, fill 0.6s $easeOutQuart 0.18s;
                    }
                }

                html.desktop & {
                    &:hover {

                        &:before {
                            transform: scaleX(1);
                        }

                        .text {
                            color: $blue-1;
                        }

                        .icon {
                            border-color: $blue-1;

                            svg {
                                transform: translate(0, 2px);
                                fill: $blue-1;
                            }
                        }
                    }
                }
            }
        }
    }

    .compagny-logo {
        //display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 12;
        background-color: white;
        padding: 5rem 10rem 5rem 0;

        &:before {
            content: "";
            position: absolute;
            width: 100vw;
            background-color: white;
            height: 100%;
            right: 0;
            top: 0;
        }

    }

    @media screen and (max-width: $mobile) {
        min-height: 100vh;
        height: auto;
        padding: 0 !important;

        &.small {
            height: 48rem;
            min-height: 48rem;

            .block-background,
            .grid {
                height: 100%;
            }
        }

        .block-background {
            height: 90vh;
        }

        .grid {
            height: 90vh;
        }

        .r-1 {
            justify-content: flex-end;
            padding-bottom: 6rem;

            h1 {
                font-size: 4.4rem;
                line-height: 5rem;
            }

            .subtitle {
                display: none;
            }

            .buttons {
                flex-direction: column;

                .button-rect {
                    margin: 1rem 0 0 0;
                }
            }
        }

        .metas {
            position: relative;
            right: auto;
            top: auto;
            transform: translate(0, 0);

            .news {
                background-color: white;

                .socials {
                    display: none;
                }

                .list-news {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    border: none;
                    padding-left: 0;
                    margin-top: 0;
                    border-bottom: 2px solid rgba(170, 170, 170, 0.3);

                    .container-news {
                        order: 1;
                        width: 100%;
                        flex: 0 0 auto;
                    }

                    .new-item {
                        background-color: $red-1;

                        a {
                            display: flex;
                            align-items: center;
                        }

                        .new-item__thumbnail {
                            flex: 0 0 auto;
                            width: 12.5rem;
                            height: 9.2rem;

                            .button-play {
                                display: none;
                            }
                        }

                        .new-item__title {
                            margin-top: 0;
                            width: 100%;
                            padding: 0 2rem;
                        }
                    }

                    .button-inline {
                        margin-top: 0;
                        order: 3;
                        margin-left: auto;
                        margin-right: 3rem;

                        .text {
                            color: $blue-1;
                        }

                        svg {
                            fill: $blue-1;
                        }
                    }

                    .navigation {
                        margin-top: 0;
                        order: 2;
                        padding: 2rem 0 2rem 3rem;

                        .arrows {
                            display: none;
                        }

                        .bullets {

                            span {
                                background-color: $blue-1;
                                opacity: 0.3;
                            }
                        }
                    }
                }
            }

            .video {
                height: 9.6rem;
                padding-left: 0;
                border-left: 0;

                a {
                    display: flex;
                    height: 100%;
                    width: 100%;
                }

                .video__thumbnail {
                    flex: 0 0 auto;
                    width: 12.5rem;
                    height: 100%;
                }

                .video__title {
                    display: flex;
                    align-items: center;
                    padding: 0 2rem;
                    margin-top: 0;
                    background-color: $red-1;
                    width: 100%;
                }
            }
        }

        .compagny-logo {
            height: 11rem;
            padding: 0;
            display: flex;
            align-items: center;

            img {
                max-width: 80%;
                height: auto;
            }
        }

        .anchors {
            display: none;
        }
    }

}