/* Webfont: SuisseIntl-SemiBold */@font-face {
    font-family: 'SuisseIntlSemiBold';
    src: url("../fonts/653d9381828e9577fb1e417dc047f89d.eot"); /* IE9*/
    src: url("../fonts/653d9381828e9577fb1e417dc047f89d.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/653d9381828e9577fb1e417dc047f89d.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/653d9381828e9577fb1e417dc047f89d.woff") format("woff"), /* chrome、firefox */
    url("../fonts/653d9381828e9577fb1e417dc047f89d.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/653d9381828e9577fb1e417dc047f89d.svg#SuisseIntl-SemiBold") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: SuisseIntl-Light */@font-face {
    font-family: 'SuisseIntlLight';
    src: url('../fonts/SuisseIntl-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/SuisseIntl-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/SuisseIntl-Light.woff') format('woff'), /* Modern Browsers */
    url('../fonts/SuisseIntl-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/SuisseIntl-Light.svg#SuisseIntl-Light') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SuisseIntl-Book */@font-face {
    font-family: 'SuisseIntlBook';
    src: url("../fonts/17241fb9c2ffb3ec11eba762e771435f.eot"); /* IE9*/
    src: url("../fonts/17241fb9c2ffb3ec11eba762e771435f.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../fonts/17241fb9c2ffb3ec11eba762e771435f.woff2") format("woff2"), /* chrome、firefox */
    url("../fonts/17241fb9c2ffb3ec11eba762e771435f.woff") format("woff"), /* chrome、firefox */
    url("../fonts/17241fb9c2ffb3ec11eba762e771435f.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../fonts/17241fb9c2ffb3ec11eba762e771435f.svg#Suisse Int'l Book") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: SuisseIntl-Bold */@font-face {
    font-family: 'SuisseIntl';
    src: url('../fonts/SuisseIntl-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/SuisseIntl-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/SuisseIntl-Bold.woff') format('woff'), /* Modern Browsers */
    url('../fonts/SuisseIntl-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/SuisseIntl-Bold.svg#SuisseIntl-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

@mixin fontSize($font-size, $line-height:null, $letter-spacing:null){
    font-size:$font-size;
    @if $line-height == null {line-height:$font-size;}
    @else {line-height:$line-height;}
    @if $letter-spacing == null {letter-spacing:0;}
    @else {letter-spacing:$letter-spacing;}
}

@mixin SuisseIntlSemiBold($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SuisseIntlSemiBold', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin SuisseIntlLight($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SuisseIntlLight', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin SuisseIntlBook($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SuisseIntlBook', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}

@mixin SuisseIntl($font-size:null, $line-height:null, $letter-spacing:null) {
    font-family: 'SuisseIntl', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @if $font-size {
        @include fontSize($font-size, $line-height, $letter-spacing);
    }
}
