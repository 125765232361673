#menu-mobile {
    @include autoAlpha(0);
    @include full-fixed;
    z-index: 100000;
    overflow-y: auto;

    .menu-mobile__background {
        @include full-absolute;
        background-color: white;
        transform: scaleX(0);
        transform-origin: 0 0;
        z-index: 1;
        pointer-events: none;
    }

    .menu-mobile__inner {
        overflow-y: scroll;
        z-index: 2;
    }

    .menu-mobile__top,
    .menu-mobile__tools,
    .menu-mobile__content,
    .menu-mobile__search,
    .menu-mobile__bottom {
        opacity: 0;
        transform: translate(0, 10px);
    }

    .menu-mobile__top {
        position: relative;
        z-index: 2;
        height: 9rem;
        display: flex;
        align-items: center;
        padding: 0 3rem;
        border-bottom: 1px solid #EDEDED;

        .logo {
            height: 100%;
            display: flex;
            align-items: center;

            a {
                display: block;

                svg {
                    width: 20rem;
                    height: auto;
                }
            }
        }

        .close {
            margin-left: auto;

            svg {
                width: 2.2rem;
                height: auto;
            }
        }
    }

    .menu-mobile__tools {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        height: 7.4rem;
        border-bottom: 1px solid #EDEDED;
        padding: 0 3rem;

        .lang {

            select {
                padding-right: 16px;
                @include SuisseIntlSemiBold(1.2rem, 1.2rem);
                letter-spacing: 0.86px;
                text-transform: uppercase;
                color: $blue-1;
                background: transparent url('../images/common/arrow-down.png') no-repeat right center;
                background-size: 8px 6px;
            }
        }

        .search {
            margin-left: auto;

            svg {
                width: 1.8rem;
                height: auto;
            }
        }
    }

    .menu-mobile__search {
        z-index: 2;

        .menu-mobile__search-inner {
            position: relative;
            overflow: hidden;
            height: 0;
        }

        form {
            background-color: #F2F4F6;
            padding: 0 3rem;
            height: 100%;
            display: flex;
            align-items: center;

            input {
                flex: 2 1 auto;
                padding-right: 4rem;
            }

            svg {
                flex: 0 0 auto;
                width: 2.2rem;
                height: auto;
            }
        }
    }

    .menu-mobile__content {
        padding: 3rem 3rem 23rem 3rem;
        position: relative;
        z-index: 2;

        .main {

            .master {
                margin-top: 4rem;

                &:first-child {
                    margin-top: 0;
                }

                .title {
                    @include SuisseIntlSemiBold(2.2rem, 3.2rem);
                    color: $blue-1;

                    a {
                        display: flex;
                        align-items: center;
                    }

                    svg {
                        flex: 0 0 auto;
                        width: 1.2rem;
                        height: auto;
                        stroke: $blue-1;
                        margin-left: auto;
                    }
                }

                &.disabled {
                    .title {
                        a {
                            pointer-events: none;
                        }
                    }
                }

                &.anchors {

                    .title {
                        svg {
                            display: none;
                        }
                    }

                    .child {
                        display: none;
                    }
                }
            }

            .child {
                overflow: hidden;
                //height: 0;
                @include SuisseIntlSemiBold(1.8rem, 2.6rem);
                color: $blue-2;
                padding-right: 5rem;

                li {
                    margin-top: 3rem;
                }
            }
        }
    }

    .menu-mobile__bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 18rem;
        z-index: 2;

        .socials {
            padding: 0 3rem;
            background-color: rgba(255, 255, 255, 0.9);
            //backdrop-filter: blur(2px);
            border-top: 1px solid #EDEDED;
            height: 9rem;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            ul {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: 3rem;

                li {
                    width: 4rem;
                    height: 4rem;

                    a {
                        width: 4rem;
                        height: 4rem;
                        border: 1px solid rgba(0, 24, 51, 0.3);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }

                    svg {
                        width: 1.4rem;
                        height: auto;
                        fill: $blue-1;
                    }

                    &.facebook {
                        margin-left: 1rem;

                        svg {
                            width: 0.7rem;
                        }
                    }

                    &.youtube {
                        margin-left: 1rem;
                    }
                }
            }
        }

        .contact {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translate(0, calc(100% - 9rem));
            z-index: 4;
            transition: transform 0.8s $easeInOutQuart;

            &.open {
                transform: translate(0, 0);

                .contact__topbar {
                    svg {
                        transform: rotate(0);
                    }
                }
            }

            .contact__topbar {
                height: 9rem;
                background-color: $red-1;
                color: white;
                @include SuisseIntlSemiBold(1.8rem, 1.8rem);
                display: flex;
                align-items: center;
                padding: 0 3rem;
                border-bottom: 1px solid $red-2;

                svg {
                    margin-left: auto;
                    stroke: white;
                    transform: rotate(-180deg);
                    width: 1.2rem;
                    height: auto;
                    transition: transform 0.6s $easeOutQuart;
                }
            }

            .contact__content {
                padding: 5rem 3rem;
                background-color: $red-1;
                @include SuisseIntlSemiBold(1.4rem, 2rem);
                color: white;

                p {
                    margin-top: 3rem;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .inline-link {
                    a {
                        .icon {
                            svg {
                                fill: white;
                            }
                        }

                        .arrow-right {
                            fill: white;
                        }
                    }
                }

                ul {
                    padding-left: 3rem;
                    margin-top: 0.6rem;

                    li {
                        position: relative;

                        &:before {
                            position: absolute;
                            left: -2rem;
                            top: 50%;
                            content: "";
                            background-color: white;
                            border-radius: 50%;
                            width: 0.6rem;
                            height: 0.6rem;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }
    }

}