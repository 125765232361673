.block-image-hero {
    border-top: none !important;

    .background-img {
        @include full-absolute;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:before {
            pointer-events: none;
            @include full-absolute;
            content: "";
            background: rgb(0,24,51);
            background: linear-gradient(270deg, rgba(0,24,51,0) 0%, rgba(0,24,51,0.4) 100%);
            z-index: 2;
        }
    }

    .grid {
        z-index: 2;
        color: white;

        .up-title {
            color: white;
        }

        .text-content {
            margin-top: 3rem;
            color: white;

            h2,
            h3 {
                @include SuisseIntlLight();
                color: white;
            }
        }

        .buttons {
            margin-top: 4rem;
        }
    }

}