h1 {
    @include SuisseIntl(6.4rem, 6.8rem);
    color: $blue-1;
}

h2 {
    @include SuisseIntlSemiBold(4.4rem, 5.2rem);
    color: $blue-1;
}

h3 {
    @include SuisseIntlSemiBold(3.8rem, 4.4rem);
    color: $blue-1;
}

h4 {
    @include SuisseIntlBook(2.4rem, 3.2rem);
}

h5 {
    @include SuisseIntlSemiBold(1.6rem, 2.2rem);
}

h6 {
    @include SuisseIntlSemiBold(1.6rem, 2.2rem);
}

.up-title {
    @include SuisseIntlSemiBold(1rem, 1.6rem);
    letter-spacing: 0.42em;
    text-transform: uppercase;
    color: $blue-3;
}

.subtitle {
    @include SuisseIntlBook(1.8rem, 2.6rem);
    color: $blue-3;
    margin-top: 3rem;
}

.text-content {
    @include SuisseIntlBook(1.6rem, 2.4rem);
    color: $blue-1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 3rem;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        color: $blue-3;
        font-size: 1.8rem;
        line-height: 2.6rem;
    }

    ul,
    ol,
    p {
        margin-top: 3rem;

        &:first-child {
            margin-top: 0;
        }
    }

    ul {
        padding-left: 4rem;

        li {
            position: relative;
            margin-top: 1rem;

            &:first-child {
                margin-top: 0;
            }

            &:before {
                content: "";
                position: absolute;
                left: -2.5rem;
                top: 1rem;
                width: 0.6rem;
                height: 0.6rem;
                background-color: $blue-1;
                border-radius: 50%;
            }
        }
    }

    b,
    strong {
        @include SuisseIntlSemiBold();
    }

    i,
    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    img,
    embed {
        display: block;
        width: 100%;
        height: auto;
    }
}

/**
QUOTE
 */
.quote {
    margin-top: 3rem;

    &:first-child {
        margin-top: 0;
    }

    .quote__icon {
        left: 0;
        top: 0;
        position: absolute;
        width: 4rem;
        height: auto;
        transform: translate(-3rem, -1rem);
        z-index: 1;
    }

    .quote__content {
        position: relative;
        z-index: 2;

        p {
            margin-top: 2rem;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .quote__author {
        margin-top: 2rem;
        display: flex;
        align-items: center;

        .author__portrait {
            flex: 0 0 auto;
            width: 7rem;
            height: 7rem;
            border-radius: 50%;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .author__metas {
            margin-left: 3rem;

            .name {
                @include SuisseIntlSemiBold(1.8rem, 2.2rem);
            }

            .function {
                margin-top: 0.6rem;
                @include SuisseIntlBook(1.6rem, 1.8rem);
            }
        }
    }
}

@media screen and (max-width: $mobile) {

    h1 {
        font-size: 4.4rem;
        line-height: 5rem;
    }

    h2 {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }

    h3 {
        font-size: 3.2rem;
        line-height: 3.8rem;
    }

    .text-content {

        h3 {
            font-size: 2.8rem;
            line-height: 3.8rem;
        }
    }

    .up-title {
        font-size: 0.8rem;
        line-height: 1;
    }

    .quote {
        .quote__icon {
            transform: translate(-2rem, -1rem);
        }
    }
}