.block-boxes {

    .grid {
        z-index: 2;
    }

    .column-10 {
        @include set-grid-prop(margin-left, 1, 1);
    }

    .r-1 {

        .text-content {
            margin-top: 2rem;
        }
    }

    .r-2 {
        margin-top: 6rem ;

        .column-10 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 4rem;
            grid-row-gap: 4rem;
        }
    }

    .box-item {
        background-color: white;
        box-shadow: 0 12px 24px 0 rgba(0, 24, 51, 0.16);
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .title {
            margin-top: 3rem;
            @include SuisseIntlSemiBold(2.4rem, 3.2rem);
            color: $blue-1;

            &:first-child {
                margin-top: 0;
            }
        }

        .text-content {
            margin-top: 3rem;
            margin-bottom: 3rem;
        }

        .button-rect {
            flex: 0 0 auto;
            margin-top: auto;
        }
    }

    .pattern {
        position: absolute;
        right: 0;
        bottom: 0;
        @extend .bg-pattern__grey;
        width: 80rem;
        height: 80rem;
        z-index: 0;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: white;
            width: 200%;
            height: 100%;
            transform: translate(-100%, -50%) skewX(-45deg);
            z-index: 2;
        }
    }

    @media screen and (max-width: $mobile) {

        .r-2 {
            .column-10 {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .box-item {
            padding: 3rem;

            .title {
                font-size: 2.2rem;
                line-height: 2.8rem;
            }
        }

        .pattern {
            display: none;
        }
    }

}