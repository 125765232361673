.block-skills {
    padding: 0;

    .grid {
        width: 100%;
        max-width: 100%;
    }

    .r-1 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 30rem);
        background-color: white;

        .up-title {
            position: absolute;
            top: 6rem;
            left: 7rem;
            z-index: 10;
            pointer-events: none;
        }

        .grid-item {
            position: relative;
            height: 100%;
            width: 100%;
            border-left: 1px solid rgba(170, 170, 170, 0.3);
            border-top: 1px solid rgba(170, 170, 170, 0.3);
            overflow: hidden;

            html.desktop & {
                &:hover {
                    .thumbnail {
                        opacity: 1;

                        img {
                            transform: scale(1);
                        }
                    }

                    .up-title,
                    .nb,
                    .title {
                        color: white;
                    }

                    .button-arrow-circle {
                        border-color: $red-1;
                        background-color: $red-1;

                        svg {
                            transform: translate(4px, 0);
                            fill: white;
                        }
                    }
                }
            }

            a {
                height: 100%;
                width: 100%;
                padding: 4rem;
                display: flex;
                position: relative;
                z-index: 2;
                flex-direction: column;
                justify-content: flex-end;
            }

            .thumbnail {
                @include full-absolute;
                z-index: 1;
                opacity: 0;
                transition: opacity 1.2s $easeOutQuart;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    transform: scale(1.1);
                    transition: transform 1.2s $easeInOutQuart;
                }

                &:after {
                    @include full-absolute;
                    content: "";
                    background: rgb(0,24,51);
                    background: linear-gradient(180deg, rgba(0,24,51,0) 0%, rgba(0,24,51,1) 100%);
                    z-index: 2;
                }
            }

            .nb {
                position: relative;
                z-index: 2;
                @include SuisseIntlLight(1.8rem, 1.8rem);
                color: $red-1;
                transition: color 0.6s $easeOutQuart;
            }

            .title {
                position: relative;
                z-index: 2;
                margin-top: 2rem;
                @include SuisseIntlSemiBold(2.4rem, 3.2rem);
                color: $blue-1;
                transition: color 0.6s $easeOutQuart;
            }

            .button-arrow-circle  {
                position: relative;
                z-index: 2;
                margin-top: 3rem;
            }

            &.g-1 {
                @extend .bg-pattern__grey;
                border-left: 0;
                grid-column: 1 / span 2;
                grid-row: 1/ span 2;

                a {
                    padding-left: 7rem;
                }

                &:before {
                    content: "";
                    position: absolute;
                    background-color: white;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 2;
                    transform: translate(-50%, 0) skewX(-40deg);
                }

                .nb {
                    font-size: 2rem;
                    line-height: 2rem;
                }

                .title {
                    font-size: 3.8rem;
                    line-height: 4.2rem;
                }
            }

            &.g-1,
            &.g-2,
            &.g-3 {
                border-top: none;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        margin-top: 10rem !important;

        .r-1 {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, auto);

            .grid-item {
                grid-column: 1 / span 1 !important;
                grid-row: span 1 !important;
                border-top: 1px solid rgba(170, 170, 170, 0.3) !important;

                a {
                    padding: 3rem !important;
                }

                .nb {
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                }

                .title {
                    margin-top: 1rem;
                    font-size: 2.1rem;
                    line-height: 2.8rem;
                }

                .button-arrow-circle {
                    margin-top: 0;
                    @include center-y;
                    right: 2rem;
                }

                &.g-1 {
                    background: transparent;
                    overflow: visible;

                    &:before {
                        display: none;
                    }

                    .up-title {
                        top: -4rem;
                        left: 3rem;
                    }

                    .nb {
                        font-size: 1.4rem;
                        line-height: 1.4rem;
                    }

                    .title {
                        font-size: 3.4rem;
                        line-height: 3.8rem;
                    }

                    .button-arrow-circle {
                        width: 4.6rem;
                        height: 4.6rem;

                        svg {
                            width: 2rem;
                        }
                    }
                }
            }
        }
    }
}