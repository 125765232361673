/**
INLINE LINK
 */
.inline-link {
    position: relative;
    color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-top: 3rem;

    a {
        padding: 1.6rem 0;
        display: flex;
        align-items: center;

        .icon {
            width: 2.2rem;
            height: 2.2rem;

            svg {
                object-fit: contain;
                width: 100%;
                height: 100%;
                transition: fill 0.6s $easeOutQuart;
            }
        }

        .text {
            margin-left: 2rem;
            @include SuisseIntlSemiBold(1.4rem, 1.6rem);
            transition: color 0.6s $easeOutQuart;
        }

        .arrow-right {
            margin-left: auto;
            width: 1.8rem;
            height: auto;
            transition: fill 0.6s $easeOutQuart, transform 0.6s $easeInOutQuart;
        }
    }

    & + .inline-link {
        border-top: none;
        margin-top: 0;
    }

    html.desktop & {
        &:hover {
            a {
                .text {
                    color: $red-1;
                }

                .arrow-right {
                    fill: $red-1;
                    transform: translate(0.4rem, 0);
                }
            }
        }
    }

    &.red {
        border-color: rgba(0, 24, 51, 0.3);

        a {

            .icon {

                svg {
                    fill: $red-1;
                }
            }

            .text {
                color: $blue-1;
            }

            .arrow-right {
                fill: $blue-1;
            }
        }
    }
}

/**
BUTTON RECT
 */
.button-rect {
    display: inline-block;
    transition: color 0.6s $easeOutQuart;

    a {
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem 2.4rem;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 0.6s $easeInOutQuart;
        }
    }

    .text {
        position: relative;
        z-index: 1;
        @include SuisseIntlSemiBold(1.4rem, 1.4rem);
    }

    svg {
        position: relative;
        z-index: 1;
        width: 1.7rem;
        height: auto;
        margin-left: 1rem;
        transform-origin: 0 0;
        transition: transform 0.6s $easeInOutQuart 0.12s, fill 0.6s $easeOutQuart 0.12s;
    }

    html.desktop & {
        &:hover {

            a {
                &:before {
                    transform: scaleX(1);
                }
            }

            svg {
                transform: translate(0.4rem, 0);
            }
        }
    }

    &.white {
        color: white;

        a {
            &:before {
                background-color: white;
            }
        }

        svg {
            fill: white;
        }

        html.desktop & {
            &:hover {
                color: $blue-1;

                svg {
                    fill: $blue-1;
                }
            }
        }
    }

    &.full {
        color: $red-1;
        background-color: white;

        a {
            &:before {
                background-color: $red-1;
            }
        }

        svg {
            fill: $red-1;
        }

        html.desktop & {

            &:hover {
                color: white;
                //background-color: $red-1;

                svg {
                    fill: white;
                }
            }
        }
    }

    &.red {
        color: white;
        background-color: $red-1;

        a {
            border-color: $red-1;

            &:before {
                background-color: $red-3;
            }
        }

        svg {
            fill: white;
        }

        html.desktop & {
            &:hover {
                color: white;

                svg {
                    fill: white;
                }
            }
        }
    }

    &.load-more {
        svg {
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }

        html.desktop & {
            &:hover {
                svg {
                    transform: translate(0, 4px) rotate(90deg);
                }
            }
        }
    }
}

/**
BUTTON LINE
 */
.button-inline {
    display: inline-block;

    a {
        display: flex;
        align-items: center;
    }

    .text {
        @include SuisseIntlSemiBold(1.4rem, 1.4rem);
        color: white;
    }

    svg {
        margin-top: 0.2rem;
        fill: white;
        width: 1.7rem;
        height: auto;
        margin-left: 1rem;
        transition: transform 0.6s $easeInOutQuart;
    }

    html.desktop & {
        &:hover {
            svg {
                transform: translate(4px, 0);
            }
        }
    }
}

/**
BUTTON ARROW CIRLCE
 */
.button-arrow-circle {
    display: flex;
    border-radius: 50%;
    width: 4.6rem;
    height: 4.6rem;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(170, 170, 170, 0.3);
    background-color: white;
    transition: background-color 0.6s $easeOutQuart, border-color 0.6s $easeOutQuart;

    &.alpha {
        background-color: transparent;

        svg {
            fill: white;
        }
    }

    html.desktop & {
        &:hover {
            border-color: $red-1;
            background-color: $red-1;

            svg {
                transform: translate(4px, 0);
                fill: white;
            }
        }
    }

    svg {
        width: 2rem;
        height: auto;
        fill: $blue-1;
        z-index: 3;
        transition: transform 0.6s $easeInOutQuart, fill 0.6s $easeOutQuart;
    }

    &.large {
        width: 7rem;
        height: 7rem;
        border-width: 2px;

        svg {
            width: 3rem;
        }
    }
}

/**
GO TOP
 */
.button-go-top {
    @include autoAlpha(0);
    width: 6.4rem;
    height: 6.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    cursor: pointer;
    right: 6rem;
    bottom: 12rem;
    z-index: 50;

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $red-1;
        transition: transform 0.4s $easeInOutQuart, box-shadow 0.6s $easeOutQuart;
    }

    svg {
        width: 2rem;
        height: auto;
        transition: transform 0.6s $easeInOutQuart;
    }

    html.desktop & {
        &:hover {

            .circle {
                transform: scale(1.1);
                box-shadow: 0 4px 12px 0 rgba(0, 24, 51, 0.4);
            }

            svg {
                transform: translate(0, -2px);
            }
        }
    }
}

@media screen and (max-width: $mobile) {

    .button-rect {
        display: block;
        width: 100%;

        a {
            justify-content: flex-start;

            svg {
                margin-left: auto;
            }
        }
    }

    .button-go-top {
        display: none !important;
    }
}