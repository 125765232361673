#single-realisation {

    .single-realisation__head {
        height: 50rem;
        background-color: $blue-1;
        padding-top: 18rem;
        overflow: hidden;

        .grid,
        .row {
            z-index: 2;
            height: 100%;
        }

        h1 {
            color: white;
            @include SuisseIntlLight(6.4rem, 6.8rem);
        }

        .category {
            margin-top: 3rem;
            display: flex;
            align-items: center;

            .legend {
                padding-top: 0.2rem;
                @extend .up-title;
            }

            ul {
                display: flex;
                align-items: center;

                li {
                    @include SuisseIntlSemiBold(1.8rem, 1.8rem);
                    color: white;
                    margin-left: 1.6rem;
                }
            }
        }

        .pattern {
            z-index: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            bottom: 0;
            @extend .bg-pattern__blue;
            width: 50rem;
            height: 50rem;
            z-index: 0;
            pointer-events: none;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: $blue-1;
                width: 200%;
                height: 100%;
                transform: translate(-100%, -50%) skewX(-45deg);
                z-index: 2;
            }
        }

        .more {
            position: absolute;
            @include set-grid-prop(left, 7, 8);
            bottom: 3rem;

            .more__inner {
                display: flex;
                align-items: center;
            }

            svg {
                width: 1rem;
                height: auto;
            }

            .text {
                margin-left: 2rem;
                @extend .up-title;
            }
        }

        .rect-arrows {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 10;
            display: flex;
            align-items: center;

            .prev,
            .next {
                cursor: pointer;;
                width: 8rem;
                height: 8rem;
                background-color: $red-1;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }

                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background-color: $red-3;
                    left: 0;
                    top: 0;
                    z-index: 0;
                    transform: scaleX(0);
                    transform-origin: 0 0;
                    transition: transform 0.6s $easeInOutQuart;
                }

                svg {
                    width: 3rem;
                    height: auto;
                    transition: transform 0.6s $easeInOutQuart;
                }

                html.desktop & {
                    &:hover {
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }

            .prev {

                svg {
                    transform: rotate(-180deg);
                }

                html.desktop & {
                    &:hover {
                        svg {
                            transform: translate(-4px, 0) rotate(-180deg);
                        }
                    }
                }
            }

            .next {
                margin-left: 1px;
                html.desktop & {
                    &:hover {
                        svg {
                            transform: translate(4px, 0);
                        }
                    }
                }
            }
        }
    }

    .single-realisation__content {
        padding-bottom: 10rem;
        position: relative;
        z-index: 2;

        .c-1 {
            .block-images-slider {
                padding: 0 !important;
                margin-top: -8rem;

                + .quote-content,
                + .text-content {
                    border-top: none;
                    padding-top: 0;
                }
            }

            .quote-content,
            .text-content {
                margin-left: 10rem;
                margin-top: 4rem;
                border-top: 1px solid rgba(170, 170, 170, 0.3);
                padding-top: 4rem;
            }

            .quote-content {
                padding-top: 6rem;
            }
        }

        .c-2 {
            padding-top: 6rem;
            @include set-grid-prop(margin-left, 1, 1);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;

            .meta-item {
                border-top: 1px solid rgba(170, 170, 170, 0.7);
                padding-top: 3rem;

                &:nth-child(1),
                &:nth-child(2) {
                    padding-top: 0;
                    border-top: 0;
                }

                .meta-item__legend {
                    @include SuisseIntlBook(1.4rem, 1.6rem);
                    color: $blue-3;
                }

                .meta-item__value {
                    margin-top: 1rem;
                    @include SuisseIntlSemiBold(1.6rem, 1.8rem);
                    color: $blue-1;
                }

            }
        }

    }

    .section-related {
        padding: 10rem 0 !important;
        background-color: $blue-1;
        border-top: none;

        .r-1 {
            margin-top: 6rem;
        }

        .tools {
            margin-bottom: 6rem;
            display: flex;
            align-items: center;
            @include SuisseIntlSemiBold(1.4rem, 1.4rem);

            > div {
                margin-left: 3rem;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .counter {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            color: $blue-1;

            .line {
                width: 0.6rem;
                height: 1px;
                background-color: $blue-1;
                margin: 0 1.4rem;
            }
        }

        .progress {
            flex: 1 1 auto;
            background-color: rgba(0, 24, 51, 0.09);
            height: 1px;
            width: 100%;

            .progress__line {
                width: 100%;
                height: 1px;
                background-color: $red-1;
                transform: scaleX(0);
                transform-origin: 0 0;
                transition: transform 0.6s $easeInOutQuart;
            }
        }

        .arrows {
            flex: 0 0 auto;

            .prev,
            .next {
                border-color: rgba(170, 170, 170, 0.3);

                svg {
                    stroke: $blue-1;
                }
            }
        }

        .swiper {
            width: 100%;
            overflow: visible;
            margin-left: 0;
        }

        &.blue {
            color: white;

            h4,
            h3,
            .text-content {
                color: white
            }

            .counter {
                color: white;

                .line {
                    background-color: white;
                }
            }

            .progress {
                background-color: rgba(255, 255, 255, 0.3);

                .progress__line {
                    background-color: white;
                }
            }

            .arrows {

                .prev,
                .next {
                    border-color: white;

                    svg {
                        stroke: white;
                    }
                }
            }

            .swiper {

                &:before {
                    background-color: $blue-1;
                }
            }

        }
    }

    @media screen and (max-width: $mobile) {
        .single-realisation__head {
            height: auto;

            .r-1 {
                padding-bottom: 11rem;
            }

            h1 {
                font-size: 4.4rem;
                line-height: 5rem;
            }

            .category {
                flex-direction: column;
                align-items: flex-start;

                ul {
                    margin-top: 1rem;

                    li {
                        margin-left: 0;
                        font-size: 1.8rem;
                        line-height: 1.2;
                    }
                }
            }

            .more {
                left: 0;
            }

            .rect-arrows {
                bottom: -4rem;
            }

            .pattern {
                width: 30rem;
                height: 30rem;
            }
        }

        .single-realisation__content {

            .c-1 {
                margin-top: 6rem;
                order: 2;

                .block-images-slider {
                    margin-top: 0;
                }

                .text-content,
                .quote-content {
                    margin-left: 0;
                }
            }

            .c-2 {
                padding-top: 10rem;
                order: 1;
            }
        }
    }

}