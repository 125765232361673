#footer {
    background-color: $blue-1;

    .footer__top {
        height: 10rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        .grid,
        .row {
            height: 100%;
        }

        .r-1 {
            display: flex;
            align-items: center;
        }

        .logo {
            height: 100%;
            display: flex;
            align-items: center;

            a {
                display: block;

                svg {
                    width: 20rem;
                    height: auto;
                }
            }
        }

        .socials {
            margin-left: auto;
            display: flex;
            align-items: center;

            .legend {
                @include SuisseIntlSemiBold(1rem, 1rem);
                letter-spacing: 0.38em;
                color: white;
                opacity: 0.7;
                text-transform: uppercase;
            }

            ul {
                display: flex;
                align-items: center;
                margin-left: 3rem;

                li {
                    width: 4rem;
                    height: 4rem;

                    a {
                        width: 4rem;
                        height: 4rem;
                        border: 1px solid rgba(255, 255, 255, 0.3);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        transition: border-color 0.6s $easeOutQuart;

                        html.desktop & {
                            &:hover {
                                border-color: white;
                            }
                        }
                    }

                    svg {
                        width: 1.4rem;
                        height: auto;
                    }

                    &.facebook {
                        margin-left: 1rem;

                        svg {
                            width: 0.7rem;
                        }
                    }

                    &.youtube {
                        margin-left: 1rem;
                    }
                }
            }
        }
    }

    .footer__content {
        padding: 5rem 0;

        .c-1 {
            @include SuisseIntlBook(1.6rem, 2.4rem);
        }

        .c-2 {
            @include set-grid-prop(margin-left, 3, 2);
            @include set-grid-prop(width, 3, 4);

            ul {
                display: grid;
                grid-gap: 1.6rem 5rem;
                grid-column: 2;
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: minmax(min-content, max-content);
                @include SuisseIntlBook(1.6rem, 2rem);
                color: white;

                a {
                    position: relative;
                    padding-bottom: 0.6rem;

                    &:before {
                        content: "";
                        background-color: white;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform-origin: 0 0;
                        transform: scaleX(0);
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }
                }
            }
        }
    }

    .footer__bottom {
        padding: 2.4rem 0;
        border-top: 1px solid rgba(255, 255, 255, 0.3);

        ul {
            @include SuisseIntlBook(1.2rem, 1.2rem);
            color: white;
            display: flex;
            align-items: center;

            li {
                margin-left: 1rem;
                padding-left: 1rem;
                border-left: 1px solid white;

                a {
                    position: relative;
                    padding-bottom: 0.6rem;

                    &:before {
                        content: "";
                        background-color: white;
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform-origin: 0 0;
                        transform: scaleX(0);
                        transition: transform 0.6s $easeInOutQuart;
                    }

                    html.desktop & {
                        &:hover {
                            &:before {
                                transform: scaleX(1);
                            }
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    border-left: none;
                }
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .footer__top {

            .logo {

                a {
                    svg {
                        width: 18rem;
                    }
                }
            }

            .socials {

                .legend {
                    display: none;
                }

                ul {
                    margin-left: 0;
                }
            }
        }

        .footer__content {
            padding: 0;

            .grid {
                width: 100%;
            }

            [class^='column'] {
                padding: 4rem 3rem;
            }

            .c-2 {
                order: 1;

                ul {
                    font-size: 1.6rem;
                    line-height: 1.8rem;
                }
            }

            .c-1 {
                border-top: 1px solid rgba(255, 255, 255, 0.3);
                order: 2;
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
    }

}